import deepmerge from "deepmerge";
import { action, computed, observable, reaction, runInAction } from "mobx";

import {
  DATE_FORMATS,
  DateTime,
  isDefined,
  MAXIMUM_DATE_MILLISECOND,
  upsertItem
} from "@bps/utils";
import { hasMore } from "@libs/api/utils/paging.utils.ts";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  InvoiceItemDto,
  ItemType,
  UpsertInvoiceItemNewDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import {
  AppointmentStatusCode,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  AddEncounterDto,
  ClinicalDataType,
  ClinicalNoteFormat,
  ClinicalNoteSectionElement,
  ClinicalNoteSections,
  ClinicalRecordDto,
  CodedFieldClinicalDataItemDto,
  CodedText,
  DischargeClinicalDataDto,
  DischargeDataItemDto,
  DocumentWriterTab,
  EncounterClinicalDataDto,
  EncounterClinicalNoteDto,
  EncounterLocation,
  EncounterStatus,
  EncounterType,
  EocInitialConsultDateAndVisitCount,
  EpisodeOfCareDto,
  GetEncountersDto,
  InteractionsFilter,
  MedicalCertainty,
  MedicalHistoryClinicalDataItemDto,
  TodaysNotes,
  TodaysNotesHeading
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { LaunchFrom } from "@libs/routing/routes.ts";
import {
  maybePromiseObservable,
  QueryResult
} from "@libs/utils/promise-observable/promise-observable.utils.ts";
import { mergeModels, mergePaginationResults } from "@libs/utils/utils.ts";
import { ClinicalTaskFilterValues } from "@shared-types/clinical/clinical-task-filter-values.type.ts";
import { ClinicalTaskStatus } from "@shared-types/clinical/clinical-task-status.enum.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";
import type { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";
import {
  getClinicalActivityDtos,
  getClinicalTaskDtos,
  getSideOfBody
} from "@stores/clinical/utils/utils.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { User } from "@stores/core/models/User.ts";
import { UserTask } from "@stores/inbox/models/UserTask.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";

import {
  getClinicalDataLastUpdatedDate,
  getClinicalNoteFormat,
  mapToInvoiceItems,
  sortByDischargeItemCreatedDateDesc,
  toStructuredNotes
} from "../utils/clinical.utils.ts";
import { TabConditionChecker } from "./clinical-tab/TabConditionChecker.ts";
import { ClinicalActivity } from "./ClinicalActivity.ts";
import { ClinicalDocument } from "./ClinicalDocument.ts";
import { ClinicalRecordFormsValuesStash } from "./ClinicalRecordFormsValuesStash.ts";
import { ClinicalTask } from "./ClinicalTask.ts";
import { Encounter } from "./Encounter.ts";
import { EncounterClinicalData } from "./EncounterClinicalData.ts";
import { Interaction } from "./Interaction.ts";
import { Measurement } from "./Measurement.ts";
import { StashedEncounterClinicalData } from "./StashedEncounterClinicalData.ts";

export type ClosedEncountersFilter = Omit<
  GetEncountersDto,
  "patientId" | "statuses"
>;

export interface CorrespondenceFilter {
  direction?: string;
  types?: string[];
  statuses?: string[];
  searchText?: string;
  confidential?: boolean;
}

export interface InvestigationFilter {
  searchText: string;
  showOtherConfidentialRequest: boolean;
  showOtherConfidentialReport: boolean;
} // Intentionally left blank for https://dev.azure.com/bpcloud-dev/BpCloud/_workitems/edit/24901

export class ClinicalRecord extends Model<ClinicalRecordDto> {
  constructor(
    private store: IRootStore,
    dto: ClinicalRecordDto,
    encounterId?: string | undefined //no encounterId for viewOnly records
  ) {
    super(dto);
    this.currentEncounterId = encounterId;
    // ⚠⚠⚠ ️Sync DB clinical data with stashed/persisted for clinical forms data.
    reaction(
      () => this._encounterClinicalData?.dto,
      dto => {
        if (dto) {
          if (!this._stashedEncounterClinicalData) {
            runInAction(() => {
              this._stashedEncounterClinicalData =
                new StashedEncounterClinicalData(dto);
            });

            this.store.clinical.ui.tabs.currentPatientRecordTab?.resetAllDirty();
          } else {
            this._stashedEncounterClinicalData.updateOriginalDtoFromDto(dto);
          }
        }
      }
    );
  }

  get clinical(): ClinicalStore {
    return this.store.clinical;
  }

  get core(): CoreStore {
    return this.store.core;
  }
  get correspondence(): CorrespondenceStore {
    return this.store.correspondence;
  }

  get practice(): PracticeStore {
    return this.store.practice;
  }

  get booking(): BookingStore {
    return this.store.booking;
  }

  get acc() {
    return this.store.acc;
  }

  get billing() {
    return this.store.billing;
  }

  get inbox() {
    return this.store.inbox;
  }

  get notification() {
    return this.store.notification;
  }

  get areNotesEntered() {
    return (
      !!this.mainFreeText ||
      this.notesHeadings?.some(x => x.freeText) ||
      this.notesHeadings?.some(
        x => x.structuredNotes && x.structuredNotes?.length > 0
      )
    );
  }

  get isClearingInvoiceItemsRequired() {
    return (
      !!this.calendarEvent?.noChargeComment && this.invoiceItems.length > 0
    );
  }

  @observable
  showEncounterExistsPrompt: boolean = false;

  @action
  setShowEncounterExistsPrompt = (showPrompt: boolean) => {
    this.showEncounterExistsPrompt = showPrompt;
  };

  @observable
  private _encounterClinicalData: EncounterClinicalData | undefined;

  @observable
  private _stashedEncounterClinicalData:
    | StashedEncounterClinicalData
    | undefined;

  public clinicalRecordFormsValuesStash: ClinicalRecordFormsValuesStash =
    new ClinicalRecordFormsValuesStash(this.clinical.ui);

  // we need to keep the condition discharge clinical data separate because you may
  // need to load this data for a condition that is not linked to the encounter
  @observable.ref
  private _currentConditionDischargeDataItems:
    | DischargeDataItemDto[]
    | undefined;

  @observable
  private defaultOrCurrentMeasurementData: Measurement[];

  @observable
  interactionResults?: QueryResult<Interaction>;

  @observable
  nextInteractionsPromise = maybePromiseObservable<QueryResult<Interaction>>();

  @observable
  closedEncounterResult?: QueryResult<Encounter>;

  @observable
  nextClosedEncounterPromise = maybePromiseObservable<QueryResult<Encounter>>();

  @observable
  patientPastReasonForVisitResult?: QueryResult<CodedText>;

  @observable
  patientPastReasonForVisitPromise =
    maybePromiseObservable<QueryResult<CodedText>>();

  @observable
  pastProviders?: User[];

  @observable
  saving: boolean;

  @observable
  lastSaved: Date | undefined;

  @observable
  finalisedByClient: boolean;

  @observable
  interactionTimelineFilter: InteractionsFilter = {};

  @observable
  nextCorrespondencePromise =
    maybePromiseObservable<QueryResult<ClinicalDocument>>();

  // multiEncounters this is set by the constructor as we now have the encounter before ClinicalRecord created
  currentEncounterId: string | undefined;

  @observable
  episodeOfCare: EpisodeOfCareDto | undefined;

  encounterDiscardedByUser: boolean = false;

  @computed
  get calendarEventId(): string | undefined {
    return this.openEncounter?.calendarEventId;
  }

  @computed
  get calendarEvent() {
    return this.calendarEventId
      ? this.booking.calendarEventsMap.get(this.calendarEventId)
      : undefined;
  }

  @observable
  linkedEocEncounters: Encounter[] | undefined;

  @observable
  public selectedFullBodyImageIndex: number;

  @observable
  public selectedSOTAPBodyImageIndex: number;

  public conditions = observable.array<ICondition>([]);

  public patientClinicalTasksPromise = maybePromiseObservable<ClinicalTask[]>();
  public patientClinicalActivitiesPromise =
    maybePromiseObservable<ClinicalActivity[]>();

  @observable
  pastVisitsSearch: string | undefined;

  @observable
  mainFreeText: string | undefined;

  @observable
  notesHeadings: TodaysNotesHeading[] = [];

  invoiceItems = observable.array<InvoiceItemDto>([]);

  @computed
  get patientPastReasonForVisit() {
    return this.patientPastReasonForVisitResult?.results ?? [];
  }

  getDischargeStatus(businessRoleCode: string | undefined) {
    const currentDischargeStatus =
      businessRoleCode &&
      this.getLatestDischargeClinicalDataItem(businessRoleCode).dischargeStatus;

    if (currentDischargeStatus !== undefined) {
      return currentDischargeStatus;
    }

    return currentDischargeStatus;
  }

  @computed
  get latestEncounterDischargeClinicalDataItem() {
    return this.getLatestDischargeClinicalDataItem(
      this.openEncounter?.businessRole
    );
  }

  getLatestDischargeClinicalDataItem(businessRoleCode: string | undefined) {
    const multiProviderEnabled = this.core.hasPermissions(
      Permission.MultiProviderClaimsAllowed
    );

    const dataItems = this.clinicalData?.discharge?.dataItems?.sort(
      sortByDischargeItemCreatedDateDesc
    );

    let dischargeDataItem: DischargeDataItemDto | undefined;
    if (dataItems && dataItems.length > 0) {
      dischargeDataItem = dataItems?.find(
        x => !multiProviderEnabled || x.businessRoleCode === businessRoleCode
      );
    }

    if (!dischargeDataItem) {
      dischargeDataItem = {
        businessRoleCode
      };
    }

    return dischargeDataItem;
  }

  @observable
  public patientUserTasks: UserTask[] = [];

  @action
  public async loadPatientUserTasks() {
    const query = await this.inbox.getUserTasks({ patientId: this.id });
    runInAction(() => {
      this.patientUserTasks = query.results;
    });
  }

  dischargeInProgress = (businessRoleCode?: string | undefined) => {
    const businessRole = businessRoleCode ?? this.openEncounter?.businessRole;

    const status = this.getDischargeStatus(businessRole);

    if (status === DischargeStatus.Completed) return false;
    if (status === DischargeStatus.ReadyToFinalise) return true;

    const dischargeTab =
      this.clinical.ui.tabs.currentPatientRecordTab?.allTabs.find(
        t =>
          t.type === ClinicalDataType.Discharge &&
          t.contextId === `${this.episodeOfCare?.id}::${businessRole}`
      );

    return !!dischargeTab;
  };

  dischargeInProgressOrCompleted = (businessRoleCode?: string | undefined) => {
    const businessRole = businessRoleCode ?? this.openEncounter?.businessRole;

    return (
      this.dischargeInProgress(businessRole) ||
      this.getDischargeStatus(businessRole) === DischargeStatus.Completed
    );
  };

  // for multiEncounters it returns the encounter from the encounterId passed through constructor
  @computed
  get openEncounter() {
    if (this.currentEncounterId) {
      return this.clinical.encounterMap.get(this.currentEncounterId);
    }
    return undefined;
  }

  @computed
  get isPartiallyFilled(): boolean {
    const hasNotes = !!this.mainFreeText || this.notesHeadings.length > 0;

    const hasReasonForVisit =
      !!this.clinicalData?.reasonForVisit?.reasonForVisits.length;
    return (hasNotes && !hasReasonForVisit) || (!hasNotes && hasReasonForVisit);
  }

  @computed
  get isFilled(): boolean {
    return (
      !!this.clinicalData?.reasonForVisit?.reasonForVisits.length ||
      !!this.mainFreeText ||
      !!this.notesHeadings.length
    );
  }

  @computed
  get hasPartiallyFilledEncounter(): boolean {
    return !!this.openEncounter && this.isPartiallyFilled;
  }

  @computed
  get isSafeToClose(): boolean {
    if (this.clinicalData?.reasonForVisit) {
      return this.clinicalData?.reasonForVisit.reasonForVisits.length > 0;
    }

    return false;
  }

  @computed
  get interactions() {
    return (this.interactionResults && this.interactionResults.results) || [];
  }

  @computed
  get isExistConfidentialClinicalTasksWithOtherUsers() {
    return this.clinicalTasks.some(x => {
      return !!x.secGroupId && !this.core.hasAccessToSecGroup(x.secGroupId);
    });
  }

  @computed
  get isExistConfidentialClosedEncountersWithOtherUsers() {
    return this.closedEncounters.some(x => {
      return x.secGroupId && !this.core.hasAccessToSecGroup(x.secGroupId);
    });
  }

  @observable
  encounterResult?: Encounter[];

  @action
  async loadEncounters(
    options: {
      ignoreCache?: boolean;
    } = { ignoreCache: true }
  ) {
    if (options.ignoreCache || !this.encounterResult) {
      const fetchEncounters = this.store.clinical.fetchEncounters({
        patientId: this.id
      });

      const result = await fetchEncounters;

      runInAction(() => {
        this.encounterResult = result.results;
      });

      return result;
    }

    return this.encounterResult;
  }

  @computed
  get closedEncounters() {
    return this.closedEncounterResult
      ? this.closedEncounterResult.results
          .filter(x => x.patientId === this.id)
          .sort((a, b): number => {
            return a.startDateTime < b.startDateTime ? 1 : -1;
          })
      : [];
  }

  @computed
  get hasMoreInteractions() {
    return this.interactionResults
      ? hasMore(this.interactionResults)
      : undefined;
  }

  @computed
  get hasMoreClosedEncounters() {
    return this.closedEncounterResult
      ? hasMore(this.closedEncounterResult)
      : undefined;
  }

  @computed
  get hasReactionsRecorded() {
    return !!this.reactions?.length || !!this.clinicalData?.reaction?.nilKnown;
  }

  @computed
  get alcoholIntakeLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.alcoholConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get tobaccoIntakeLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.tobaccoConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get substanceUseIntakeLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.substanceUseConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get socialHistoryLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.socialHistoryConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get physicalActivityLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.physicalActivityConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get workHistoryLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.workHistoryConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get familyHistoryLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.familyHistoryConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get sleepLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.sleepConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get treatmentLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.treatmentAndEducation
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get treatmentPlanLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.patientTreatmentPlan
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get dermatomesAndMyotomesLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.dermatomesAndMyotomesConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get generalExamLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.generalExamConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT);
  }

  @computed
  get hofpcLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.hofpcConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_TIME_FORMAT);
  }

  @computed
  get clinicalFlagsLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.clinicalFlagsConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get systemsReviewLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.systemsReviewConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  @computed
  get bodyAreaLastUpdatedDate() {
    return getClinicalDataLastUpdatedDate(
      this.clinicalData?.bodyAreaConfirmed
    )?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT);
  }

  /* Clinical Data */
  @computed
  get clinicalData() {
    return this._encounterClinicalData;
  }

  @computed
  get stashedClinicalData() {
    return this._stashedEncounterClinicalData;
  }

  @computed
  get currentConditionDischargeDataItems() {
    return this._currentConditionDischargeDataItems;
  }

  @computed
  get measurements() {
    return this.defaultOrCurrentMeasurementData;
  }

  @computed
  get clinicalTasks() {
    return (this.patientClinicalTasksPromise.value ?? [])
      .slice()
      .sort(ClinicalRecord.clinicalTaskSort);
  }

  @computed
  get clinicalActivities() {
    return this.patientClinicalActivitiesPromise.value ?? [];
  }

  @computed
  get sameRoleEncounters() {
    return this.linkedEocEncounters?.filter(
      x => x.businessRole === this.openEncounter?.businessRole
    );
  }

  @computed
  get isFollowOnEncounter() {
    if (this.core.hasPermissions(Permission.MultiProviderClaimsAllowed)) {
      const sameRoleEncounters = this.sameRoleEncounters;
      return sameRoleEncounters ? sameRoleEncounters.length > 1 : false;
    } else {
      return (this.linkedEocEncounters ?? []).length > 1;
    }
  }

  @action
  setPastVisitsSearch(search: string | undefined) {
    this.pastVisitsSearch = search;
  }

  @action
  loadPatient() {
    return this.store.practice.getContact(this.id);
  }

  @action
  async loadEpisodeOfCare() {
    const openEncounter = this.openEncounter;
    if (openEncounter) {
      const episodeOfCareId = openEncounter.episodeOfCareId;

      let episodeOfCare: EpisodeOfCareDto | undefined;

      if (episodeOfCareId) {
        episodeOfCare = await this.clinical.getEpisodeOfCare(episodeOfCareId);
      } else {
        episodeOfCare = undefined;
      }

      runInAction(() => {
        this.episodeOfCare = episodeOfCare;
      });
    }
  }

  @action
  async updateReasonForVisitFromDiagnosis() {
    const clinicalData: EncounterClinicalDataDto = {};

    if (
      this.clinicalData?.reasonForVisit?.reasonForVisits &&
      this.clinicalData?.reasonForVisit?.reasonForVisits.length > 0
    ) {
      return;
    }

    const reasonForVisits = this.getReasonForVisits();
    if (reasonForVisits && reasonForVisits.length > 0) {
      clinicalData.reasonForVisit = {
        ...this.clinicalData?.reasonForVisit,
        reasonForVisits
      };

      await this.saveClinicalData(clinicalData);
    }
  }

  async updateMedicalHistoryFromDiagnosis() {
    if (
      !this.core.hasPermissions([Permission.ACCClaimSyncMedicalHistoryAllowed])
    ) {
      return;
    }

    if (this.claimId) {
      const claim = await this.acc.getClaim(this.claimId);

      // Check claim status
      const allowCreateMedicalHistory = claim.claimStatus
        ? [ClaimStatuses.Accepted, ClaimStatuses.Declined].includes(
            claim.claimStatus
          )
        : false;

      if (allowCreateMedicalHistory && claim.referralIn) {
        // Create MedicalHisotry
        const currentDiagnoses = claim.currentDiagnoses ?? [];

        // Add medicalHiostry clinicalData
        const medicalHistories =
          this.clinicalData?.medicalHistory?.medicalHistories ?? [];

        let newMedicalHistories: MedicalHistoryClinicalDataItemDto[] = [];
        newMedicalHistories = currentDiagnoses.reduce((acc, diagnosis) => {
          const terminology = diagnosis.terminology;

          const diagnosisCode = {
            code: terminology?.code ?? "",
            codeSystem: terminology?.codeSystem,
            originalText: terminology?.text ?? "",
            version: terminology?.version
          };

          // Create new medical history item
          const newItem: MedicalHistoryClinicalDataItemDto = {
            active: true,
            diagnosis: terminology?.code,
            diagnosisCode,
            diagnosisSide: getSideOfBody(diagnosis.diagnosisSide),
            isPrimary: true,
            episodeOfCareId: this.episodeOfCare?.id,
            certainty: MedicalCertainty.Confirmed,
            diagnosisDate:
              claim.accidentDate?.toISODate() ?? DateTime.today().toISODate()
          };

          // Check if an entry with the same diagnosis code and date already exists
          const index = medicalHistories.findIndex(
            x =>
              x.diagnosisCode?.code === newItem.diagnosisCode?.code &&
              x.diagnosisDate === newItem.diagnosisDate
          );

          if (index < 0) {
            acc.push(newItem);
          } else {
            acc[index] = newItem;
          }

          return medicalHistories;
        }, medicalHistories as MedicalHistoryClinicalDataItemDto[]);

        await this.saveClinicalData({
          medicalHistory: {
            eTag: this.clinicalData?.medicalHistory?.eTag,
            noSignificantHistory: false,
            medicalHistories: newMedicalHistories
          }
        });
      }
    }
  }

  @action
  async removeConditionDiagnosisFromReasonForVisit() {
    const clinicalData: EncounterClinicalDataDto = {};

    const rfcData = this.clinicalData?.reasonForVisit?.reasonForVisits;

    if (rfcData && rfcData.length > 0) {
      clinicalData.reasonForVisit = {
        ...this.clinicalData?.reasonForVisit,
        reasonForVisits: rfcData.filter(
          x =>
            !this.episodeOfCare?.diagnoses?.some(
              y => y.diagnosisCode?.code === x.code
            )
        )
      };

      await this.saveClinicalData(clinicalData);
    }
  }

  getReasonForVisits() {
    const reasonForVisits: CodedFieldClinicalDataItemDto[] = [];

    const { diagnoses } = this.episodeOfCare || {};
    diagnoses?.forEach(diagnosis => {
      const { code, originalText, version, codeSystem } =
        diagnosis?.diagnosisCode || {};
      if (code && originalText)
        reasonForVisits.push({
          code,
          originalText,
          codeSystem,
          version
        });
    });

    return reasonForVisits;
  }

  @computed
  get patient() {
    return this.store.practice.contactsMap.get(this.id);
  }

  /**
   * The latest reactions (allergies)
   */
  @computed
  get reactions() {
    return this.clinicalData?.reaction?.reactions || [];
  }

  /**
   * The latest medical history
   */
  @computed
  get medicalHistories() {
    return this.clinicalData?.medicalHistory?.medicalHistories || [];
  }

  @computed
  get imagingRequests() {
    return this.clinicalData?.imagingRequests?.imagingRequests || [];
  }

  @computed
  get imagingReports() {
    return this.clinicalData?.imagingReports?.imagingReports || [];
  }

  @computed
  get claimId() {
    return this.calendarEvent?.claim?.id ?? this.condition?.claim?.id;
  }

  @action
  async updateInteractionResults(interaction: Interaction) {
    runInAction(() => {
      if (this.interactionResults?.results) {
        this.interactionResults.results = mergeModels(
          this.interactionResults.results,
          [interaction]
        );
      }
    });
  }

  @action
  async loadInteractions(
    filter?: InteractionsFilter,
    options: {
      ignoreCache?: boolean;
    } = { ignoreCache: true }
  ) {
    if (options.ignoreCache || !this.interactionResults) {
      if (filter) {
        runInAction(() => {
          this.interactionTimelineFilter = filter;
        });
      }

      const fetchInteractions = this.store.clinical.fetchInteractions({
        patientId: this.id,
        take: this.store.clinical.encounterPageSize,
        total: true,
        ...filter
      });

      runInAction(() => {
        this.nextInteractionsPromise.set(fetchInteractions);
      });

      const result = await fetchInteractions;
      runInAction(() => {
        result.results = result.results.filter(item => item.secGroupId == null);
      });

      runInAction(() => {
        if (!this.interactionResults || options.ignoreCache) {
          this.interactionResults = undefined;
        }
      });

      runInAction(() => {
        if (!this.interactionResults || options.ignoreCache) {
          this.interactionResults = result;
        } else {
          this.interactionResults = mergePaginationResults(
            this.interactionResults,
            result
          );
        }
      });

      return result;
    }
    return undefined;
  }

  @action
  async loadMoreInteractions() {
    const skip =
      (this.interactionResults &&
        this.interactionResults.skip + this.interactionResults.take) ||
      0;

    const fetchInteractions = this.store.clinical.fetchInteractions({
      patientId: this.id,
      skip,
      take: this.store.clinical.encounterPageSize,
      total: true,
      ...this.interactionTimelineFilter
    });
    runInAction(() => {
      this.nextInteractionsPromise.set(fetchInteractions);
    });

    const result = await fetchInteractions;

    runInAction(() => {
      if (!this.interactionResults) {
        this.interactionResults = result;
      } else {
        this.interactionResults = mergePaginationResults(
          this.interactionResults,
          result
        );
      }
    });

    return result;
  }

  @action
  async loadClosedEncounters(
    filter?: ClosedEncountersFilter,
    options: {
      ignoreCache?: boolean;
    } = { ignoreCache: true }
  ) {
    if (options.ignoreCache || !this.closedEncounterResult) {
      const fetchEncounters = this.store.clinical.fetchEncounterSearch({
        take: this.store.clinical.encounterPageSize,
        total: true,
        ...filter,
        patientId: this.id,
        statuses: [EncounterStatus.Closed]
      });
      runInAction(() => {
        this.nextClosedEncounterPromise.set(fetchEncounters);
      });

      const result = await fetchEncounters;

      runInAction(() => {
        this.closedEncounterResult = result;
      });

      return result;
    }

    return this.closedEncounterResult;
  }

  @action
  async loadMoreClosedEncounters(filter?: ClosedEncountersFilter) {
    const skip =
      (this.closedEncounterResult &&
        this.closedEncounterResult.skip + this.closedEncounterResult.take) ||
      0;

    const fetchEncounters = this.store.clinical.fetchEncounterSearch({
      skip,
      take: this.store.clinical.encounterPageSize,
      total: true,
      ...filter,
      patientId: this.id,
      statuses: [EncounterStatus.Closed]
    });

    runInAction(() => {
      this.nextClosedEncounterPromise.set(fetchEncounters);
    });

    const result = await fetchEncounters;

    runInAction(() => {
      if (!this.closedEncounterResult) {
        this.closedEncounterResult = result;
      } else {
        this.closedEncounterResult = mergePaginationResults(
          this.closedEncounterResult,
          result
        );
      }
    });

    return result;
  }

  @action
  async loadPatientPastReasonForVisits(
    options: {
      ignoreCache?: boolean;
    } = { ignoreCache: true }
  ) {
    if (options.ignoreCache || !this.patientPastReasonForVisitResult) {
      const fetchPatientPastReasonForVisits =
        this.store.clinical.fetchPatientPastReasonForVisits({
          total: true,
          patientId: this.id,
          statuses: [EncounterStatus.Closed]
        });
      runInAction(() => {
        this.patientPastReasonForVisitPromise.set(
          fetchPatientPastReasonForVisits
        );
      });

      const result = await fetchPatientPastReasonForVisits;

      runInAction(() => {
        this.patientPastReasonForVisitResult = result;
      });

      return result;
    }

    return this.patientPastReasonForVisitResult;
  }

  @action
  async loadPastProviders(
    options: {
      ignoreCache?: boolean;
    } = { ignoreCache: true }
  ) {
    if (options.ignoreCache || !this.pastProviders) {
      const result = await this.store.clinical.fetchPatientPastProviders(
        this.id
      );

      const users: User[] = await this.store.core.getUsersByIds(
        result.map(u => u.userId)
      );

      runInAction(() => {
        this.pastProviders = users;
      });

      return users;
    }

    return this.pastProviders;
  }

  @computed
  get condition() {
    return this.conditions.find(
      x => x.episodeOfCareId === this.episodeOfCare?.id
    );
  }

  getConditionByEocId(eocId: string | undefined) {
    return eocId
      ? this.conditions.find(x => x.episodeOfCareId === eocId)
      : undefined;
  }

  @action
  async loadConditions() {
    const episodeOfCares = await this.store.clinical.getPatientEpisodesOfCare(
      this.id
    );

    const episodeOfCareIds = episodeOfCares.map(x => x.id);

    const [claimEpisodesOfCare, claims, patientCalendarEvents] =
      await Promise.all([
        this.store.acc.getClaimEpisodesOfCare({
          episodesOfCare: episodeOfCareIds
        }),
        this.core.hasPermissions(Permission.ClaimRead)
          ? (await this.store.acc.fetchClaims({ patients: [this.id] })).results
          : undefined,
        this.store.booking
          .getCalendarEvents({
            attendees: [this.id]
          })
          .then(ce => ce.results)
      ]);

    await this.loadEncounters({ ignoreCache: true });
    const loadedEncounter = this.encounterResult;

    let _initialConsultDates: EocInitialConsultDateAndVisitCount[] | undefined;
    if (this.openEncounter) {
      const options = {
        episodeOfCareIds,
        patientId: this.id,
        openEncounter: this.openEncounter
      };

      const initialConsultDateAndVisitsCounts =
        await this.clinical.getInitialConsultDateAndVisitsCounts(
          options,
          loadedEncounter
        );

      _initialConsultDates = initialConsultDateAndVisitsCounts
        ? initialConsultDateAndVisitsCounts
        : undefined;
    }

    const conditionData = episodeOfCares.map(m => {
      const claimEpisodeOfCare = claimEpisodesOfCare?.find(
        x => x.episodeOfCareId === m.id && x.isActive
      );

      return {
        episodeOfCare: m,
        claimEpisodeOfCare,
        claim: claimEpisodeOfCare
          ? claims?.find(x => x.id === claimEpisodeOfCare.claimId)
          : undefined,
        patientCalendarEvents,
        eocEncounters:
          this.encounterResult?.filter(e => e.episodeOfCareId === m.id) ?? [],
        initialConsultDate: _initialConsultDates?.find(
          x => x.episodeOfCareId === m.id
        )?.initialConsultDate
      };
    });

    runInAction(() => {
      this.conditions.replace(
        conditionData.map(m => {
          return {
            episodeOfCareId: m.episodeOfCare.id,
            patientId: this.id,
            providerId: m.claim?.providerId,
            primaryDiagnosis:
              m.episodeOfCare.diagnoses?.find(x => x.isPrimaryDiagnosis)
                ?.diagnosisCode?.originalText ?? "Undiagnosed",
            injuryDate: DateTime.fromISO(m.episodeOfCare?.injuryDate),
            referralNumber: m.episodeOfCare.referralNumber,
            referralProvider: m.episodeOfCare.referralProvider,
            referralDate: DateTime.jsDateFromISO(m.episodeOfCare.referralDate),
            isReferral: m.episodeOfCare.isReferral,
            discharged: m.episodeOfCare.discharged,
            isPrivate: !m.claimEpisodeOfCare,
            createdDate: DateTime.fromISO(
              m.episodeOfCare.changeLog?.createdDate
            )!,
            sendAccForm: m.claim?.sendAccForm,
            claim: m.claim,
            calendarEventIds: m.patientCalendarEvents
              .filter(x => x.reason?.episodeOfCareId === m.episodeOfCare.id)
              .map(x => x.id),
            initialConsultDate: m.initialConsultDate,
            diagnosisSide:
              m.episodeOfCare.diagnoses?.find(x => x.isPrimaryDiagnosis)
                ?.diagnosisSide ?? ""
          };
        })
      );
    });

    return this.conditions;
  }
  @action
  async loadObservationData() {
    const result = await this.store.clinical.getObservations({
      patientId: this.id,
      take: 1000
    });
    return result;
  }

  @action
  async loadMeasurementData() {
    const result = await this.store.clinical.getMeasurements({
      patientId: this.id,
      take: 1000
    });
    runInAction(() => {
      this.defaultOrCurrentMeasurementData = result.results;
    });
    return result;
  }

  @action
  updateMeasurements(updatedMeasurements?: Measurement[]) {
    if (updatedMeasurements) {
      if (!this.defaultOrCurrentMeasurementData)
        this.defaultOrCurrentMeasurementData = [];

      for (let i = 0; i < updatedMeasurements?.length; i++) {
        let existingMeasurement: Measurement | undefined;
        if (updatedMeasurements[i].type === ClinicalDataType.RAND36) {
          existingMeasurement = this.defaultOrCurrentMeasurementData.find(
            m =>
              m.encounterId === updatedMeasurements[i].encounterId &&
              m.type === updatedMeasurements[i].type &&
              updatedMeasurements[i].summary === m.summary
          );
        } else {
          existingMeasurement = this.defaultOrCurrentMeasurementData.find(
            m =>
              (m.contextId &&
                m.contextId === updatedMeasurements[i].contextId) ||
              m.id === updatedMeasurements[i].id
          );
        }

        if (existingMeasurement) {
          const index =
            this.defaultOrCurrentMeasurementData.indexOf(existingMeasurement);

          this.defaultOrCurrentMeasurementData[index] = updatedMeasurements[i];
        } else {
          this.defaultOrCurrentMeasurementData.unshift(updatedMeasurements[i]);
        }
      }
    }
  }

  @action
  async loadCurrentConditionDischargeData(episodeOfCareId: string | undefined) {
    const currentEncounterDischargeData: DischargeClinicalDataDto | undefined =
      this.clinicalData?.discharge ?? {};

    const episodeOfCareClinicalDataData =
      await this.store.clinical.getEpisodeOfCareScopedClinicalData({
        patientId: this.id,
        episodeOfCareId,
        types: [ClinicalDataType.Discharge]
      });

    let eocDischargeItems =
      episodeOfCareClinicalDataData.discharge?.dataItems ?? [];

    currentEncounterDischargeData?.dataItems?.forEach(x => {
      eocDischargeItems = upsertItem({
        item: x,
        array: eocDischargeItems,
        predicate: y => y.businessRoleCode === x.businessRoleCode
      });
    });

    runInAction(() => {
      this._currentConditionDischargeDataItems = eocDischargeItems
        ? eocDischargeItems
        : undefined;
    });
  }

  @action
  async loadEOCScopedClinicalData(options?: {
    episodeOfCareId?: string;
    types?: string[];
  }): Promise<EncounterClinicalDataDto | undefined> {
    if (this.openEncounter && this.openEncounter.id) {
      const episodeOfCareId =
        options?.episodeOfCareId ?? this.openEncounter.episodeOfCareId;
      if (episodeOfCareId) {
        const episodeOfCareClinicalDataData =
          await this.store.clinical.getEpisodeOfCareScopedClinicalData({
            patientId: this.id,
            episodeOfCareId,
            types: options?.types
          });

        if (episodeOfCareClinicalDataData) {
          this.removeNullProperties(episodeOfCareClinicalDataData);
          return episodeOfCareClinicalDataData;
        }
      }
    }

    return undefined;
  }

  @action
  async loadClinicalData(): Promise<void> {
    const patientScopedDataPromise =
      this.store.clinical.getPatientScopedClinicalData(this.id);

    const confirmedDataPromise =
      this.store.clinical.getClinicalDataConfirmedClinicalData(this.id);

    const [patientScopedData, confirmedData] = await Promise.all([
      patientScopedDataPromise,
      confirmedDataPromise
    ]);

    this.removeNullProperties(patientScopedData);
    this.removeNullProperties(confirmedData);
    let result = { ...patientScopedData, ...confirmedData };

    if (this.openEncounter && this.openEncounter.id) {
      const episodeOfCareClinicalDataPromise = this.loadEOCScopedClinicalData();

      const encounterClinicalDataPromise =
        this.store.clinical.getEncounterClinicalData(
          { encounterId: this.openEncounter.id },
          { ignoreCache: true }
        );

      const [encounterClinicalData, episodeOfCareClinicalData] =
        await Promise.all([
          encounterClinicalDataPromise,
          episodeOfCareClinicalDataPromise
        ]);

      if (episodeOfCareClinicalData) {
        result = { ...result, ...episodeOfCareClinicalData };
      }

      this.removeNullProperties(encounterClinicalData);
      result = { ...result, ...encounterClinicalData };
    }
    runInAction(() => {
      this._encounterClinicalData = new EncounterClinicalData(result);
    });
  }

  saveClinicalData = async (clinicalData: EncounterClinicalDataDto) => {
    let encounterId: string | undefined;
    if (!this.openEncounter) {
      const encounter = await this.addEncounter({});

      encounterId = encounter.id;
    } else {
      encounterId = this.openEncounter.id;
    }

    const encounterNote =
      await this.store.clinical.getEncounterClinicalNote(encounterId);
    if (
      !(
        encounterNote &&
        (encounterNote.clinicalDataElements?.length > 0 ||
          (encounterNote.sectionElements &&
            encounterNote.sectionElements?.length > 0))
      )
    ) {
      const notesData = this.toClinicalNoteRequest(
        this.mainFreeText,
        this.notesHeadings
      );

      await this.store.clinical.updateEncounterClinicalNote(
        notesData,
        encounterId
      );
    }

    const responseData = await this.store.clinical.updateClinicalData(
      encounterId,
      clinicalData
    );

    this.removeNullProperties(responseData);

    const keys = Object.keys(responseData);

    const dto = this.clinicalData?.dto ?? {};
    const mergedData = deepmerge(
      dto,
      {
        ...responseData
      },
      {
        // We shouldn't have to do any array merging - the source
        // should always contain the correct array to "merge" in
        arrayMerge: (target, source) => {
          return source ?? target;
        },
        customMerge: key => {
          if (keys.includes(key)) {
            return (_x, y) => {
              return y;
            };
          } else {
            return x => {
              return x;
            };
          }
        }
      }
    );

    if (this._encounterClinicalData) {
      this._encounterClinicalData?.updateFromDto(mergedData);
    } else {
      runInAction(() => {
        this._encounterClinicalData = new EncounterClinicalData(clinicalData);
      });
    }

    await this.loadStructuredNotes(encounterId, undefined, true);

    return mergedData;
  };

  updateCurrentConditionDischargeData = async (
    data: DischargeDataItemDto[] | undefined
  ) => {
    runInAction(() => {
      this._currentConditionDischargeDataItems = data;
    });
  };

  addEncounter = async (data: Partial<AddEncounterDto>) => {
    const businessRoleCode = this.core.primaryBusinessRole?.code ?? "";

    const defaultValues = {
      encounterType: EncounterType.Consultation,
      encounterLocation: EncounterLocation.Practice,
      orgUnitId: this.store.core.locationId,
      patientId: this.id,
      userId: this.store.core.userId,
      businessRole: businessRoleCode
    };

    const encounter = await this.clinical.addEncounter({
      ...defaultValues,
      ...data
    });

    await Promise.all([
      this.addAppointmentEncounters(encounter),
      this.addAppointmentTypeInvoice(encounter)
    ]);
    return encounter;
  };

  addAppointmentEncounters = async (
    encounter: Encounter,
    calendarEventId?: string
  ) => {
    const ceId = calendarEventId ?? this.calendarEventId;
    if (!ceId) {
      return;
    }

    await this.booking.addAppointmentEncounters({
      encounterId: encounter.id,
      calendarEventId: ceId,
      providerId: encounter.userId,
      businessRole: encounter.businessRole
    });
  };

  readonly emptySotapNotes: EncounterClinicalNoteDto = {
    sectionElements: [
      { sectionCode: ClinicalNoteSections.Subjective, text: "" },
      { sectionCode: ClinicalNoteSections.Objective, text: "" },
      { sectionCode: ClinicalNoteSections.Treatment, text: "" },
      { sectionCode: ClinicalNoteSections.Analysis, text: "" },
      { sectionCode: ClinicalNoteSections.Plan, text: "" }
    ],
    clinicalDataElements: [],
    sectionHeadingReferenceData: [],
    clinicalDataHeadingReferenceData: []
  };

  readonly emptySoapNotes: EncounterClinicalNoteDto = {
    sectionElements: [
      { sectionCode: ClinicalNoteSections.Subjective, text: "" },
      { sectionCode: ClinicalNoteSections.Objective, text: "" },
      { sectionCode: ClinicalNoteSections.Assessment, text: "" },
      { sectionCode: ClinicalNoteSections.Plan, text: "" }
    ],
    clinicalDataElements: [],
    sectionHeadingReferenceData: [],
    clinicalDataHeadingReferenceData: []
  };

  addAppointmentTypeInvoice = async (encounter: Encounter) => {
    await this.calendarEvent?.loadAppointmentType();

    const serviceIds =
      this.calendarEvent?.appointmentType?.defaultServiceIds || [];

    if (serviceIds.length === 0) {
      return;
    }

    const serviceDate = encounter.startDateTime.startOf("day").toISODate();

    const services = await this.billing.getServiceSearch({
      serviceIds,
      effectiveDate: serviceDate
    });

    const invoiceItems = !!this.invoiceItems.length
      ? this.invoiceItems
      : await this.createInvoiceItems(
          mapToInvoiceItems({
            services,
            serviceDate,
            gstPercent: this.billing.gstPercent,
            baseProps: this.getInvoiceItemBaseProps()
          })
        );

    runInAction(() => {
      this.invoiceItems = invoiceItems;
    });
  };

  sotapSections: string[] = [
    ClinicalNoteSections.Subjective,
    ClinicalNoteSections.Objective,
    ClinicalNoteSections.Treatment,
    ClinicalNoteSections.Analysis,
    ClinicalNoteSections.Plan
  ];

  soapSections: string[] = [
    ClinicalNoteSections.Subjective,
    ClinicalNoteSections.Objective,
    ClinicalNoteSections.Assessment,
    ClinicalNoteSections.Plan
  ];

  getInvoiceItemBaseProps = (
    orgUnitId?: string
  ): Pick<
    InvoiceItemDto,
    | "userId"
    | "calendarEventId"
    | "user"
    | "patient"
    | "patientId"
    | "itemType"
    | "accountId"
    | "locationId"
  > => {
    if (!this.patient?.firstName || !this.patient?.lastName) {
      throw Error("Cannot create an invoice when patient is not defined.");
    }

    return {
      userId: this.core.userId,
      user: {
        firstName: this.core.user?.firstName || "",
        lastName: this.core.user?.lastName || "",
        title: this.core.ref.titles.get(this.core.user?.title || "")?.text || ""
      },
      patientId: this.id,
      patient: {
        contactType: ContactType.Patient,
        firstName: this.patient.firstName,
        lastName: this.patient.lastName,
        phone: "-", // todo - This should be something other than dash
        address: this.patient?.defaultAddress
      },
      locationId:
        this.calendarEvent?.orgUnitId ?? orgUnitId ?? this.core.locationId,
      itemType: ItemType.Invoice,
      calendarEventId: this.calendarEventId,
      accountId: this.patient.primaryAccountHolder?.relatedContactId
        ? this.patient.primaryAccountHolder.relatedContactId
        : this.patient.id
    };
  };

  updateInvoice = async (
    upsertInvoiceItems: UpsertInvoiceItemNewDto[] = []
  ) => {
    if (!this.invoiceItems.length) {
      throw new Error("Invoice should exist");
    }

    await this.createCalendarEventIfRequired();

    const fetchedItems = await this.billing
      .fetchInvoiceItems({
        itemIds: upsertInvoiceItems.map(x => x.id).filter(isDefined),
        take: 1000
      })
      .then(x => x.results);

    const itemsWithEtags: UpsertInvoiceItemNewDto[] = upsertInvoiceItems.map(
      upsertItem =>
        !upsertItem.id
          ? upsertItem
          : {
              ...upsertItem,
              eTag: fetchedItems.find(
                invoiceItem => invoiceItem.id === upsertItem.id
              )?.eTag
            }
    );

    const invoiceItems = await this.updateInvoiceItems(itemsWithEtags);
    runInAction(() => {
      this.invoiceItems.replace(invoiceItems);
    });
  };

  updateInvoiceItems = async (items: UpsertInvoiceItemNewDto[]) => {
    const serviceIds = items.map(i => i.serviceId);

    const [invoiceItems] = await Promise.all([
      this.billing.upsertInvoiceItems({
        patientId: this.id,
        calendarEventId: this.calendarEventId,
        items
      }),
      // service must be loaded so the app knows that they are active
      this.billing.getServices({ serviceIds })
    ]);

    return invoiceItems;
  };

  createInvoiceItems = async (
    upsertInvoiceItems: UpsertInvoiceItemNewDto[] = [],
    orgUnitId?: string
  ) => {
    await this.createCalendarEventIfRequired(undefined, orgUnitId);

    const request: UpsertInvoiceItemNewDto[] = upsertInvoiceItems.map(
      upsertItem => ({
        ...upsertItem,
        ...this.getInvoiceItemBaseProps()
      })
    );

    const invoiceItems = await this.updateInvoiceItems(request);

    runInAction(() => {
      this.invoiceItems.replace(invoiceItems);
    });

    return this.invoiceItems;
  };

  async loadInvoiceItems() {
    if (!this.calendarEventId || !this.patient?.id) {
      return;
    }

    const invoiceItems = await this.billing
      .fetchInvoiceItems({
        calendarEventIds: [this.calendarEventId],
        ...(this.patient?.id ? { patientIds: [this.patient?.id] } : undefined)
      })
      .then(queryResult => queryResult.results);

    //Load services into map
    const serviceIds = invoiceItems.map(ii => ii.serviceId);

    if (serviceIds.length) {
      await this.billing.getServices({ serviceIds });
    }

    runInAction(() => {
      this.invoiceItems.replace(invoiceItems);
    });

    return this.invoiceItems;
  }

  public loadStructuredNotes = async (
    encounterId: string,
    format?: string,
    updateNote?: boolean
  ) => {
    let encounterNote: EncounterClinicalNoteDto | undefined;
    const hasOpenEncounter = this.openEncounter;

    if (hasOpenEncounter && encounterId) {
      encounterNote =
        await this.store.clinical.getEncounterClinicalNote(encounterId);
    }

    if (this.openEncounter?.type !== EncounterType.RecordUpdate) {
      const hasClinicalDataORSectionElements =
        encounterNote?.clinicalDataElements?.length ||
        encounterNote?.sectionElements?.length;

      if (format && format !== ClinicalNoteFormat.Default) {
        const emptyNotes =
          format === ClinicalNoteFormat.SOTAP
            ? this.emptySotapNotes
            : this.emptySoapNotes;

        encounterNote =
          hasOpenEncounter && hasClinicalDataORSectionElements
            ? { ...emptyNotes, ...encounterNote }
            : emptyNotes;
      }

      //update SOTAP/SOAP notes to capture clinical data updates for other sections
      if (hasOpenEncounter && updateNote && encounterNote) {
        const noteFormat = getClinicalNoteFormat(encounterNote);

        if (noteFormat && noteFormat !== ClinicalNoteFormat.Default) {
          const noteSections = this.generateSectionElements(
            encounterNote,
            noteFormat
          );

          //update clinical note section elements for new clinical data updates
          await this.store.clinical.updateEncounterClinicalNote(
            noteSections,
            encounterId
          );
          encounterNote.sectionElements = noteSections;
        }
      }
    }
    this.setEncounterNotes(encounterNote);
    return encounterNote;
  };

  generateSectionElements(
    clinicalNote: EncounterClinicalNoteDto,
    noteFormat: ClinicalNoteFormat
  ) {
    const expectedSections =
      noteFormat === ClinicalNoteFormat.SOTAP
        ? this.sotapSections
        : this.soapSections;

    const sections: ClinicalNoteSectionElement[] =
      clinicalNote.sectionElements ?? [];

    //get sections added by clinicaldata updates
    const dataSections = clinicalNote.sectionHeadingReferenceData?.filter(
      o => !expectedSections.includes(o.code)
    );
    if (dataSections?.length > 0) {
      const newSections: ClinicalNoteSectionElement[] = dataSections
        .filter(o => !sections.some(x => o.code === x.sectionCode))
        .map(o => {
          return { sectionCode: o.code, text: "" };
        });
      if (newSections?.length > 0) {
        sections.push(...newSections);
      }
    }
    return sections;
  }

  public setEncounterNotes = (
    clinicalNote: EncounterClinicalNoteDto | undefined
  ) => {
    if (clinicalNote) {
      const freeText =
        clinicalNote.sectionElements?.find(
          item => item.sectionCode === ClinicalNoteSections.Main
        )?.text ?? "";
      runInAction(() => {
        this.mainFreeText = freeText;
        this.notesHeadings = toStructuredNotes(
          clinicalNote,
          this.clinical.ui.tabs.currentPatientRecordTab,
          this.clinical.ref.clinicalNoteSections.keyNameValues
        );
      });
    }
  };

  public toClinicalNoteRequest = (
    mainFreeText: string | undefined,
    notesHeadings: TodaysNotesHeading[]
  ) => {
    let sectionElements: ClinicalNoteSectionElement[] = [];
    sectionElements = [
      {
        sectionCode: ClinicalNoteSections.Main,
        text: mainFreeText ?? ""
      }
    ];
    if (notesHeadings) {
      notesHeadings?.forEach(item => {
        if (item.code === ClinicalNoteSections.Main) return;
        sectionElements.push({
          sectionCode: item.code,
          text: item.freeText ?? ""
        });
      });
    }
    return sectionElements;
  };

  async getEncounterClinicalNotes(encounterId: string | undefined) {
    if (!encounterId) return undefined;

    const note =
      await this.store.clinical.getEncounterClinicalNote(encounterId);
    if (!note) return undefined;

    const todaysNote: TodaysNotes = {
      freeText:
        note?.sectionElements?.find(
          x => x.sectionCode === ClinicalNoteSections.Main
        )?.text ?? ""
    };

    todaysNote.headings = toStructuredNotes(
      note,
      this.clinical.ui.tabs.currentPatientRecordTab,
      this.clinical.ref.clinicalNoteSections.keyNameValues
    );

    return todaysNote;
  }

  async loadCalendarEvent() {
    if (this.openEncounter) {
      await this.openEncounter.loadCalendarEvent();
    } else if (this.store.clinical.activeCalendarEvent) {
      await this.store.booking.getCalendarEvent(
        this.store.clinical.activeCalendarEvent
      );
    }
  }

  async loadLinkedEncounters() {
    const encounter = this.openEncounter;
    let encounters: Encounter[] = [];
    if (encounter) {
      const episodeOfCareId = encounter.episodeOfCareId;
      if (episodeOfCareId) {
        encounters = await this.clinical.getEncounters({
          patientId: this.id,
          episodeOfCareIds: [episodeOfCareId]
        });
      }
    }

    runInAction(() => {
      this.linkedEocEncounters = encounters;
    });
  }

  private static clinicalTaskSort(a: ClinicalTask, b: ClinicalTask): number {
    const statusOrders = {
      [ClinicalTaskStatus.Overdue]: 1,
      [ClinicalTaskStatus.Today]: 2,
      [ClinicalTaskStatus.Upcoming]: 3,
      [ClinicalTaskStatus.Completed]: 4
    };

    // Order by Status
    const statusOrder =
      statusOrders[a.taskStatus ?? ClinicalTaskStatus.Completed] -
      statusOrders[b.taskStatus ?? ClinicalTaskStatus.Completed];

    // Order by DueDate
    const dueDateOrder =
      (a.dueDate
        ? DateTime.fromISO(a.dueDate).toMillis()
        : MAXIMUM_DATE_MILLISECOND) -
      (b.dueDate
        ? DateTime.fromISO(b.dueDate).toMillis()
        : MAXIMUM_DATE_MILLISECOND);

    // Order by DueVisits
    const remainingVisitsOrder =
      (a.remainingVisits ?? 0) - (b.remainingVisits ?? 0);

    return statusOrder || dueDateOrder || remainingVisitsOrder;
  }

  public getFilteredClinicalTasks(
    filter: ClinicalTaskFilterValues
  ): ClinicalTask[] {
    // apply the filter
    if (!filter || !this.patientClinicalTasksPromise.value)
      return this.clinicalTasks;

    const descriptionCodes = filter.description
      ? this.store.clinical.ref.clinicalTaskTypes.keyNameValues?.filter(x =>
          x.name.toLowerCase().includes(filter.description!.toLowerCase())
        )
      : [];

    // Check the number in the range
    const numberInRange = (x: number, min: number, max: number): boolean => {
      return (x - min) * (x - max) <= 0;
    };

    return this.clinicalTasks
      .filter(
        x =>
          (filter.priorities && filter.priorities.length > 0
            ? filter.priorities!.some(p => p === x.priority)
            : true) &&
          (x.dueDate && filter.startDateTime
            ? DateTime.fromISO(x.dueDate) >=
              DateTime.fromJSDate(filter.startDateTime!)
            : true) &&
          (x.dueDate && filter.endDateTime
            ? DateTime.fromISO(x.dueDate) <=
              DateTime.fromJSDate(filter.endDateTime!)
            : true) &&
          (x.dueInVisits && filter.dueVisits && filter.dueVisits.length > 0
            ? filter.dueVisits.some(range => {
                const arrMinMax = range.split(",").map(m => Number(m));
                return numberInRange(
                  Math.max(x.remainingVisits ?? 0, 0), // To include an overdue task
                  arrMinMax[0],
                  arrMinMax[1]
                );
              })
            : true) &&
          (filter.statuses && filter.statuses.length > 0
            ? filter.statuses!.some(s => s === x.taskStatus)
            : true) &&
          (filter.description
            ? descriptionCodes.some(c => c.key === x.taskType)
            : true) &&
          (filter.confidential
            ? true
            : this.core.hasAccessToSecGroup(x.secGroupId))
      )
      .sort(ClinicalRecord.clinicalTaskSort);
  }

  setPatientClinicalTasksPromise(promise?: PromiseLike<ClinicalTask[]>) {
    this.patientClinicalTasksPromise.set(promise ?? this.loadClinicalTasks());
  }

  async loadClinicalTasks() {
    // Get clinical tasks from the store
    const storeTasks = await this.store.clinical.getPatientClinicalTasks(
      this.id
    );

    // Get clinical tasks from the clinical data
    const clinicalTasksDto = getClinicalTaskDtos(
      this.clinicalData?.clinicalTask?.clinicalTasks,
      false
    );

    const clinicalTasks = clinicalTasksDto.map(x => new ClinicalTask(x));

    // Merge clinical tasks
    const mergedArray = [...storeTasks, ...clinicalTasks];

    // If a duplicate task exists, get the clinical task from the clinical data which is the latest changed one
    const purgedClinicalTasks = Array.from(
      mergedArray
        .reduce(
          (map: Map<string, ClinicalTask>, obj: ClinicalTask) =>
            map.set(obj.id, obj),
          new Map<string, ClinicalTask>()
        )
        .values()
    );

    const deletedTasks = this.clinicalData?.clinicalTask?.deletedItems ?? [];

    return purgedClinicalTasks.filter(
      x => !deletedTasks.some(s => s.id === x.id)
    );
  }

  loadClinicalActivities() {
    this.patientClinicalActivitiesPromise.set(this._loadClinicalActivities());
  }

  private async _loadClinicalActivities() {
    // Get clinical activities from the store
    const storeActivities =
      await this.store.clinical.getPatientClinicalActivities(this.id);

    // Get clinical activities from the clinical data
    const clinicalActivitiesDto = getClinicalActivityDtos(
      this.clinicalData?.clinicalActivity?.clinicalActivities,
      false
    );

    const clinicalActivities = clinicalActivitiesDto.map(
      x => new ClinicalActivity(x)
    );

    // Merge clinical activities
    const mergedArray = [...storeActivities, ...clinicalActivities];

    // If a duplicate activities exists, get the clinical activities from the clinical data which is the latest changed one
    const purgedClinicalActivities = Array.from(
      mergedArray
        .reduce(
          (map: Map<string, ClinicalActivity>, obj: ClinicalActivity) =>
            map.set(obj.id, obj),
          new Map<string, ClinicalActivity>()
        )
        .values()
    );

    const deletedClinicalActivities =
      this.clinicalData?.clinicalActivity?.deletedItems ?? [];

    return purgedClinicalActivities.filter(
      x => !deletedClinicalActivities.some(s => s.id === x.id)
    );
  }

  private removeNullProperties(data: EncounterClinicalDataDto) {
    Object.keys(data).forEach(o => {
      if (data[o] === null) {
        delete data[o];
      }
    });
  }

  @action
  async close(encounterId?: string) {
    await this.clinical.closeRecord(this.id, encounterId);
  }

  createCalendarEventIfRequired = async (
    noChargeComment?: string,
    orgUnitId?: string
  ) => {
    if (this.calendarEventId) {
      return;
    }

    const encounter = this.openEncounter;
    if (encounter) {
      const appointmentTypeText =
        encounter.type === EncounterType.RecordUpdate
          ? "Record*update*(system)*"
          : "Consult*(system)*";

      const appointmentTypesQuery = await this.booking.fetchAppointmentTypes({
        search: appointmentTypeText,
        includeInactive: true,
        isInternalStatic: true
      });

      const recUpdate = appointmentTypesQuery[0];
      const appointmentDuration = recUpdate.duration || 15; // 15 is a bit arbitrary as recUpdate.duration is not likely to be undefined

      const now = DateTime.now();

      const provider = await this.practice.getProvider(encounter.userId);

      // round minute down to nearest interval
      const minute = now.minute - (now.minute % appointmentDuration);
      const startTime = now.set({ minute, second: 0, millisecond: 0 });
      const endTime = startTime.plus({ minutes: appointmentDuration });
      const ce = await this.booking.addCalendarEvent({
        orgUnitId: orgUnitId ?? this.store.core.locationId,
        type: CalendarEventType.Appointment,
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        providerContractType: provider.contractTypes?.length
          ? provider.contractTypes[0]
          : undefined,
        reason: encounter.episodeOfCareId
          ? { episodeOfCareId: encounter.episodeOfCareId }
          : undefined,
        attendees: toAttendees({
          userId: encounter.userId,
          contactId: encounter.patientId
        }).map(x =>
          x.attendeeId === encounter.patientId && noChargeComment
            ? { ...x, noChargeComment }
            : x
        ),
        appointmentTypeId: recUpdate.id,
        appointmentStatus: AppointmentStatusCode.Finalised
      });
      await this.addAppointmentEncounters(encounter, ce.id);
      return ce;
    }
    return undefined;
  };

  updateDischargeStatus = async (
    status: DischargeStatus | undefined,
    businessRoleCode: string | undefined,
    newCreatedDischarge?: DischargeClinicalDataDto | undefined
  ) => {
    let clinicalDataDischarge =
      newCreatedDischarge ?? this.clinicalData?.discharge;

    if (!clinicalDataDischarge || !clinicalDataDischarge.dataItems) {
      clinicalDataDischarge = {
        dataItems: []
      };
    }

    const dataItem: DischargeDataItemDto =
      this.getLatestDischargeClinicalDataItem(businessRoleCode);

    if (dataItem?.dischargeStatus !== status) {
      const dataItemIndex = dataItem
        ? clinicalDataDischarge?.dataItems?.indexOf(dataItem)
        : undefined;

      const newDataItem: DischargeDataItemDto =
        dataItem.dischargeStatus === DischargeStatus.Reversed
          ? {
              dischargeStatus: status,
              businessRoleCode: dataItem.businessRoleCode
            }
          : {
              ...dataItem,
              dischargeStatus: status,
              dischargedByUserId: this.core.userId
            };

      if (clinicalDataDischarge.dataItems) {
        if (
          dataItemIndex === undefined ||
          dataItemIndex < 0 ||
          dataItem.dischargeStatus === DischargeStatus.Reversed
        ) {
          clinicalDataDischarge.dataItems.push(newDataItem);
        } else {
          clinicalDataDischarge.dataItems[dataItemIndex] = {
            ...newDataItem
          };
        }
      }

      const discharge: DischargeClinicalDataDto = {
        ...clinicalDataDischarge,
        eTag: this.clinicalData?.discharge?.eTag
      };

      await this.updateCurrentConditionDischargeData(discharge.dataItems);
      await this.saveClinicalData({ discharge });
      await this.loadCurrentConditionDischargeData(this.episodeOfCare?.id);
    }
  };

  deleteDischarge = async (businessRoleCode: string | undefined) => {
    const discharge = this.clinicalData?.discharge;
    const dataItems = discharge?.dataItems?.slice();

    const multiProviderEnabled = this.core.hasPermissions(
      Permission.MultiProviderClaimsAllowed
    );

    if (dataItems) {
      const index = dataItems.findIndex(
        x =>
          ((multiProviderEnabled && x.businessRoleCode === businessRoleCode) ||
            !multiProviderEnabled) &&
          x.dischargeStatus !== DischargeStatus.Reversed
      );

      if (index >= 0) {
        dataItems?.splice(index, 1);

        const newDischargeData: DischargeClinicalDataDto = {
          ...discharge,
          dataItems
        };
        await this.updateCurrentConditionDischargeData(
          newDischargeData.dataItems
        );

        await this.saveClinicalData({
          discharge: newDischargeData
        });
        this.stashedClinicalData?.resetStashedClinicalData(["discharge"]);
      }
    }
  };

  reverseDischarge = async (
    businessRoleCode: string | undefined,
    comments?: string
  ) => {
    const dischargeData = this.clinicalData?.discharge;
    const dataItems = dischargeData?.dataItems;
    const defaultDataItem: DischargeDataItemDto = {
      businessRoleCode,
      reverseDischargeComment: comments,
      dischargeStatus: DischargeStatus.Reversed
    };

    const dataItem =
      dataItems?.find(d => d.businessRoleCode === businessRoleCode) ||
      defaultDataItem;

    if (dataItem) {
      const newDataItem: DischargeDataItemDto = {
        ...dataItem,
        reverseDischargeComment: comments,
        dischargeStatus: DischargeStatus.Reversed
      };

      const newDataItems = [...(dataItems || [])];
      const dataItemIndex = dataItems?.findIndex(
        d => d.businessRoleCode === businessRoleCode
      );

      if (dataItemIndex === undefined || dataItemIndex < 0) {
        newDataItems.push(newDataItem);
      } else {
        newDataItems[dataItemIndex] = {
          ...newDataItem
        };
      }

      const newDischargeData: DischargeClinicalDataDto = {
        ...dischargeData,
        dataItems: newDataItems
      };

      await this.updateCurrentConditionDischargeData(
        newDischargeData.dataItems
      );

      await this.saveClinicalData({ discharge: newDischargeData });
      await this.loadCurrentConditionDischargeData(this.episodeOfCare?.id);
    }
  };

  continueWithDischarged = async (businessRoleCode: string | undefined) => {
    if (businessRoleCode) {
      const dischargeItem: DischargeDataItemDto = {
        businessRoleCode
      };

      const dischargeData = this.clinicalData?.discharge;

      const items = dischargeData?.dataItems ?? [];

      const newDischargeData: DischargeClinicalDataDto = {
        ...dischargeData,
        dataItems: [...items, dischargeItem]
      };

      await this.updateCurrentConditionDischargeData(
        newDischargeData.dataItems
      );

      await this.saveClinicalData({ discharge: newDischargeData });
      await this.loadCurrentConditionDischargeData(this.episodeOfCare?.id);
    }
  };

  @computed
  get tabCondition() {
    const tabChecker = new TabConditionChecker(this.clinical);
    return tabChecker.getTabCondition();
  }

  public is1stMultiRoleConsult = () => {
    if (
      this.linkedEocEncounters &&
      this.core.hasPermissions(Permission.MultiProviderClaimsAllowed)
    ) {
      return (
        this.linkedEocEncounters?.length > 1 &&
        this.sameRoleEncounters?.length === 1
      );
    }
    return false;
  };

  public filterEncountersOnCurrentRole(encounters: Encounter[]) {
    return this.core.hasPermissions(Permission.MultiProviderClaimsAllowed)
      ? encounters.filter(x => x.businessRole === this.currentBusinessRole)
      : encounters;
  }

  @computed
  get currentBusinessRole() {
    return this.openEncounter?.businessRole;
  }

  loadPatientNotices = async () => {
    await this.practice.getPatientNotices(this.id);
  };

  @action
  setDocumentDialogVisible = (value: boolean) => {
    this.correspondence.ui.setShowTemplatePicker(value, {
      hidden: false,
      userId: this.core.userId,
      onDismiss: () => this.correspondence.ui.setShowTemplatePicker(false),
      onSuccess: (documentTab: DocumentWriterTab) => {
        this.correspondence.activeDocumentTab = documentTab;
      },
      launchFrom: LaunchFrom.Clinical,
      patientId: this.id,
      encounterId: this.openEncounter?.id,
      claimId: this.calendarEvent?.claimId
    });
  };

  getClinicalOccupationWithAccOccupationId = (accOccupationId: string) => {
    const accOccupation = this.acc.ref.occupations.get(accOccupationId);

    const clinicalOccupations = this.clinical.ref.occupations.keyTextValues;

    const clinicalOccupation = clinicalOccupations.find(
      x => accOccupation?.anzscoCode?.split(",").includes(x.key)
    );

    return clinicalOccupation?.key;
  };

  getAccOccupationWithOccupationId = (clinicalOccupationId: string) => {
    const accOccupations = this.acc.ref.occupations.values;

    const accOccupation = accOccupations.find(
      x => x.anzscoCode?.split(",").includes(clinicalOccupationId)
    );
    return accOccupation?.code;
  };

  getInitialClinicalOccupation = (initialOccupation?: string) => {
    if (initialOccupation)
      return this.getClinicalOccupationWithAccOccupationId(initialOccupation);

    const savedOccupation =
      this.clinicalData?.patientDemographicUpdate?.occupation;

    if (savedOccupation) return savedOccupation;

    const patientOccupation = this.patient?.occupation;
    if (patientOccupation) return patientOccupation;

    return undefined;
  };

  @computed
  get stashedPSFSGoals() {
    const data = this.stashedClinicalData;

    const condition = this.condition;

    const psfsContext = data?.psfsContext
      ? data?.psfsContext.contexts
          .filter(c => c.diagnosis.originalText === condition?.primaryDiagnosis)
          .map(c => c.activities)
      : [];

    const activities = psfsContext[0];

    if (activities?.length > 0) {
      return activities.map(question => ({
        goal: question.text
      }));
    }
    return [];
  }
}
