import { observer } from "mobx-react-lite";

import { ActionButton, Stack, TooltipHost } from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { EpisodeOfCareDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";

interface ClaimDischargeButtonProps {
  claimStatus?: string;
  businessRoleCode: string | undefined;
  episodeOfCare: EpisodeOfCareDto;
  dischargeStatus: string | undefined;
  onClick?: () => Promise<void>;
}

export const ConditionDischargeButton: React.FC<ClaimDischargeButtonProps> =
  observer(
    ({
      claimStatus,
      episodeOfCare,
      dischargeStatus,
      businessRoleCode,
      onClick
    }) => {
      const { core } = useStores();

      const { clinicalRecord } = usePatientRecordScreenContext();
      const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();
      const dischargeEnableStatus = [
        ClaimStatuses.Accepted,
        ClaimStatuses.Accredited,
        ClaimStatuses.Declined,
        ClaimStatuses.Error,
        ClaimStatuses.Held,
        ClaimStatuses.NotAvailable,
        ClaimStatuses.NotVerified,
        ClaimStatuses.Incomplete,
        ClaimStatuses.Pending,
        ClaimStatuses.Private,
        ClaimStatuses.Queued,
        ClaimStatuses.Ready
      ];

      let enableDischarge: boolean;
      let toolTipMessage: string = "";

      const currentUser = core.user;
      const isClinAdmin = core.hasPermissions(
        Permission.ConditionDischargeOnBehalfAllowed
      );

      if (
        businessRoleCode &&
        !currentUser?.businessRoles.includes(businessRoleCode) &&
        !isClinAdmin
      ) {
        enableDischarge = false;
      } else if (
        dischargeStatus === DischargeStatus.InProgress ||
        dischargeStatus === DischargeStatus.ReadyToFinalise
      ) {
        enableDischarge = false;

        if (
          clinicalRecord.episodeOfCare &&
          clinicalRecord.episodeOfCare.id !== episodeOfCare.id
        ) {
          const diagnosis =
            clinicalRecord.episodeOfCare.diagnoses?.find(
              x => x.isPrimaryDiagnosis
            )?.diagnosisCode?.originalText ?? "Undiagnosed";
          toolTipMessage = `Currently discharging ${diagnosis}, only one discharge can be in progress`;
        }
      } else if (
        clinicalRecord.episodeOfCare &&
        clinicalRecord.episodeOfCare?.id !== episodeOfCare.id
      ) {
        enableDischarge = false;
        toolTipMessage =
          "Only the condition linked to this consult can be discharged";
      } else if (
        claimStatus &&
        dischargeStatus !== DischargeStatus.Reversed &&
        !clinicalRecord.episodeOfCare?.discharged
      ) {
        enableDischarge = dischargeEnableStatus.some(x => x === claimStatus);
        if (!enableDischarge) {
          toolTipMessage = "Cannot be discharged in current status";
        }
      } else {
        enableDischarge = true;
      }

      return (
        <Stack grow horizontal horizontalAlign="end">
          <TooltipHost content={toolTipMessage}>
            <ActionButton
              iconProps={{ iconName: "Leave" }}
              disabled={!enableDischarge || isViewOnlyOrDischarged}
              styles={{
                root: {
                  height: "auto",
                  minHeight: 25
                }
              }}
              text="Discharge"
              onClick={async () => {
                if (onClick) await onClick();
              }}
            />
          </TooltipHost>
        </Stack>
      );
    }
  );
