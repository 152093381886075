import { action, observable } from "mobx";

import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import {
  AddStatementBulkDto,
  GetStatementArgs
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { StatementGenerateFormValues } from "@modules/billing/screens/statement/components/StatementGenerateModal.type.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Statement } from "@stores/billing/models/Statement.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

export interface StatementItem {
  statement: Statement;
  contact: Contact;
}

export class StatementItemsHelper {
  constructor(private root: IRootStore) {}

  @observable
  selectedIds: string[] | undefined = [];

  @action
  setSelectedIds = (selected: string[]) => {
    this.selectedIds = selected;
  };

  selection: Selection = new Selection({
    onSelectionChanged: () => {
      const selectedItems = (
        this.selection.getSelection() as { id: string }[]
      ).map(x => x.id);
      this.setSelectedIds(selectedItems);
    }
  });

  getStatements = async (query: GetStatementArgs) => {
    return this.root.billing.getStatements({
      ...query,
      accountContactId: undefined
    });
  };

  handleSubmitAction = async (values: StatementGenerateFormValues) => {
    const req: AddStatementBulkDto = {
      date: DateTime.now().toISODate(),
      startDate: DateTime.jsDateToISODate(values.startDate),
      endDate: DateTime.jsDateToISODate(values.endDate),
      owingOver: values.valueFilter ? values.owingOver : undefined,
      creditOver: values.valueFilter ? values.creditOver : undefined,
      accountContactIds: this.selectedIds
    };
    return this.root.billing.addStatementBulk(req);
  };

  @observable total: number | undefined;

  @action setTotal = (value: number | undefined) => {
    this.total = value;
  };

  searchAccountIds = async (
    values: StatementGenerateFormValues,
    query?: PagingOptions
  ) => {
    const { endDate, startDate, owingOver, creditOver } = values;

    const res = await this.root.billing
      .getAccountIds({
        ...query,
        startDate: DateTime.jsDateToISODate(startDate),
        endDate: DateTime.jsDateToISODate(endDate),
        owingOver: owingOver && owingOver > 0 ? owingOver : undefined,
        creditOver: creditOver && creditOver > 0 ? creditOver : undefined,
        total: true
      })
      .then(x => {
        this.selection.setAllSelected(true);
        return x;
      });

    this.setTotal(res.total);

    return res;
  };

  statementGenerateFormNameOf = nameOfFactory<StatementGenerateFormValues>();

  @observable
  isStatementNewModalVisible = false;
  @action
  setIsStatementNewModalVisible = (value: boolean) => {
    this.isStatementNewModalVisible = value;
  };
}
