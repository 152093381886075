import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Stack, StandardRangesOptionsKeys, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StatementItemsContext } from "@modules/billing/screens/statement/context/StatementItemsContext.ts";
import { DatesRangePickerField } from "@ui-components/form/DatesRangePickerField/DatesRangePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { SubmissionFormDialogHeader } from "@ui-components/form/submission-form-dialog/SubmissionFormDialogHeader.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { StatementExpanderButton } from "./StatementExpanderButton.tsx";
import { StatementGenerateFormValues } from "./StatementGenerateModal.type.ts";
import { StatementLastGeneratedMessageBar } from "./StatementLastGeneratedMessageBar.tsx";
import { StatementValueFilter } from "./StatementValueFilter.tsx";

interface StatementGenerateModalFieldsProps {
  disabled?: boolean;
}

export const StatementGenerateModalFields: React.FC<StatementGenerateModalFieldsProps> =
  observer(() => {
    const {
      statementGenerateFormNameOf,
      handleSubmitAction,
      selectedIds,
      setIsStatementNewModalVisible
    } = useContext(StatementItemsContext);

    const { spacing } = useTheme();

    const onHandleSubmit = async (
      form: FormApi<StatementGenerateFormValues>
    ) => {
      await handleSubmitAction(form.getState().values);
      setIsStatementNewModalVisible(false);
    };

    return (
      <>
        <SubmissionFormDialogHeader
          onCancel={() => setIsStatementNewModalVisible(false)}
          title="Statement"
        />
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              overflowY: "auto",
              padding: "0 24px"
            }
          }}
        >
          <StatementLastGeneratedMessageBar />

          <DatesRangePickerField
            styles={{ root: { width: "50%" }, fieldGroup: { width: 200 } }}
            placeholder="Date(s)"
            required
            standardRanges={[
              StandardRangesOptionsKeys.previousMonth,
              StandardRangesOptionsKeys.thisMonth
            ]}
            label="Date range"
            endDateName={statementGenerateFormNameOf("endDate")}
            startDateName={statementGenerateFormNameOf("startDate")}
            endDateProps={{ maxDate: DateTime.jsDateNow() }}
            closeCalloutOnSelection
            renderCloseBtn
            hidePasteStartButton
          />
          <YesNoToggleField
            label="Filter by account value"
            name={statementGenerateFormNameOf("valueFilter")}
          />

          <FieldCondition
            when={statementGenerateFormNameOf("valueFilter")}
            is={(value: boolean) => !!value}
          >
            <StatementValueFilter />
          </FieldCondition>

          <StatementExpanderButton />
        </Stack>

        <FormSubmitButtons
          promptOnCancel
          hideButtonsSeparator
          onCancel={() => setIsStatementNewModalVisible(false)}
          onSubmit={onHandleSubmit}
          submitButtonProps={{
            id: "submit-statement-form",
            text: "Generate",
            iconProps: undefined,
            disabled: selectedIds?.length === 0
          }}
          styles={{ root: { padding: spacing.l1 } }}
          cancelButtonProps={{ text: "Cancel" }}
        />
      </>
    );
  });
