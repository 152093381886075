import { observer } from "mobx-react-lite";
import { useMemo } from "react";

import { FontSizes, NoDataTile, useFormContext } from "@bps/fluent-ui";

import { DidNotArriveListFilter } from "./DidNotArriveDetailFilter.tsx";

export const DidNotArriveNoData = observer(() => {
  const {
    state: { dirty },
    actions: { reset }
  } = useFormContext<DidNotArriveListFilter>();

  const noDataProps = useMemo(
    () => ({
      textProps: {
        text: dirty
          ? "0 matches found"
          : "No entries for incomplete appointments"
      },
      linkProps: dirty
        ? {
            text: "Clear filters",
            onClick: () => reset()
          }
        : {
            hidden: true
          },
      styles: dirty
        ? {
            root: {
              padding: 8,
              height: "100%",
              fontSize: FontSizes.size68,
              fontStyle: "italics"
            }
          }
        : {
            root: {
              height: "100%"
            }
          },
      greyView: !dirty ? true : undefined
    }),
    [dirty, reset]
  );

  return <NoDataTile {...noDataProps} showBoxShadow={false} />;
});
