import { useState } from "react";

import { Link, MessageBar, MessageBarType, Stack } from "@bps/fluent-ui";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { usePatientRecordScreenContext } from "../../context/PatientRecordScreenContext.ts";
import { ReverseDischargeDialog } from "./claims/ReverseDischargeDialog.tsx";

interface ConditionDischargeWarningProps {
  clinicalRecord: ClinicalRecord;
}

export const ConditionDischargeWarning: React.FunctionComponent<
  ConditionDischargeWarningProps
> = ({ clinicalRecord }) => {
  const { core } = useStores();

  const { getLatestActiveDischargeClinicalDataItem } =
    usePatientRecordScreenContext();

  const [hiddenReverseDischargeDialog, setHiddenReverseDischargeDialog] =
    useState<boolean>(true);

  const businessRole = clinicalRecord?.openEncounter?.businessRole;

  const latestDischarge = getLatestActiveDischargeClinicalDataItem(
    businessRole,
    clinicalRecord.clinicalData?.discharge?.dataItems
  );

  const handleReverseDischargeConfirmed = async (comments?: string) => {
    const businessRole = clinicalRecord?.openEncounter?.businessRole;

    await clinicalRecord.reverseDischarge(businessRole, comments);
    setHiddenReverseDischargeDialog(true);
  };

  const continueWithDischarge = async () => {
    await clinicalRecord.continueWithDischarged(businessRole);
  };

  const doesNotHaveExistingDischarge =
    latestDischarge.dischargeStatus !== DischargeStatus.Completed;

  // If we're not a primary business guy, ignore everything and only show what's there.
  const isNonProvider = !core.user?.isProviderClass;

  if (isNonProvider) {
    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        <Stack horizontal verticalAlign="center">
          This condition is discharged.
        </Stack>
      </MessageBar>
    );
  }

  const latestIsDischarged =
    latestDischarge.dischargeStatus &&
    latestDischarge.dischargeStatus === DischargeStatus.Completed;

  if (latestIsDischarged) {
    return (
      <MessageBar messageBarType={MessageBarType.warning}>
        <Stack horizontal verticalAlign="center">
          This condition is discharged.{" "}
          <Link onClick={() => setHiddenReverseDischargeDialog(false)}>
            Reverse the discharge
          </Link>{" "}
          to edit.
        </Stack>
        <ReverseDischargeDialog
          hidden={hiddenReverseDischargeDialog}
          onConfirm={handleReverseDischargeConfirmed}
          onCancel={() => setHiddenReverseDischargeDialog(true)}
        />
      </MessageBar>
    );
  }

  if (doesNotHaveExistingDischarge) {
    <MessageBar messageBarType={MessageBarType.warning}>
      <Stack horizontal verticalAlign="center">
        This condition has been discharged.{" "}
        <Link onClick={() => continueWithDischarge()}>Continue</Link>{" "}
        Consultation and reactivate condition on save.
      </Stack>
    </MessageBar>;
  }

  return null;
};
