import {
  dataAttribute,
  DataAttributes,
  FontWeights,
  Heading,
  IconButton,
  IGroupHeaderProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";

import {
  contextMenuStyles,
  subMarginStyle
} from "./ObservationsList.styles.ts";
import { GroupedMeasurement } from "./utils.ts";

interface ObservationHeaderProps {
  props: IGroupHeaderProps;
  selectedKey?: string;
  observations: Record<string, Record<string, GroupedMeasurement[]>>;
  clinicalRecord: ClinicalRecord;
}

export const ObservationHeader: React.FC<ObservationHeaderProps> = ({
  props,
  observations
}) => {
  const group = props?.group!;
  const name = group.name || "";

  const observation = observations[group.key];

  const groupCount = Object.keys(observation).length;
  const isFinal = props?.groups?.length
    ? props?.groupIndex === props?.groups?.length - 1
    : false;

  const isCollapsed = props.group?.isCollapsed || false;
  const theme = useTheme();
  const DEPTH_1 = 1;

  return (
    <Stack
      horizontal
      onClick={() => {
        if (props?.onToggleCollapse && props?.group) {
          props.onToggleCollapse(props.group);
        }
      }}
      styles={{
        root: {
          borderTop: `1px ${theme.palette.neutralLight} solid`,
          borderBottom:
            isFinal && isCollapsed
              ? `1px ${theme.palette.neutralLight} solid`
              : undefined
        }
      }}
    >
      <IconButton
        {...dataAttribute(DataAttributes.Element, `${name}-collapse`)}
        iconProps={{
          iconName: isCollapsed ? "ChevronRight" : "ChevronDown"
        }}
        styles={group.level === DEPTH_1 ? contextMenuStyles : subMarginStyle}
      />
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 4 }}
      >
        <Heading
          variant="section-sub-heading"
          styles={{
            root: {
              fontWeight: FontWeights.bold
            }
          }}
        >
          {name} ({groupCount})
        </Heading>
      </Stack>
    </Stack>
  );
};
