import {
  FontSizes,
  FontWeights,
  NativeList,
  noWrap,
  Stack,
  StackItem,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { useStores } from "@stores/hooks/useStores.ts";

import { ConfidentialByProviderText } from "../investigations/side-panel/ConfidentialByProviderText.tsx";
import {
  EN_DASH,
  formatDateStringToTime,
  isBloodPressureOrPulse,
  MeasurementItemProps,
  ObservationsLabels
} from "./utils.ts";

export const GroupByDateMeasurementsView: React.FC<MeasurementItemProps> = ({
  primaryKey,
  measurements
}) => {
  const theme = useTheme();
  const { core } = useStores();
  const hasMultipleMeasurementsSameDay = measurements.some(
    (measurement, index) =>
      index > 0 &&
      DateTime.fromISO(measurement.timestamp).hasSame(
        DateTime.fromISO(measurements[index - 1].timestamp),
        "day"
      )
  );

  return (
    <Stack
      styles={{
        root: {
          paddingLeft: 32
        }
      }}
      key={primaryKey}
    >
      {(isBloodPressureOrPulse(primaryKey) ||
        hasMultipleMeasurementsSameDay) && (
        <Stack horizontal verticalAlign="center">
          <Text
            styles={{
              root: {
                fontSize: FontSizes.size12,
                color: theme.palette.neutralSecondary
              }
            }}
          >
            {primaryKey}
          </Text>
        </Stack>
      )}

      {isBloodPressureOrPulse(primaryKey) ? (
        <NativeList>
          {measurements.map(measurement => {
            let displayText = "";

            const labelText = measurement.label
              ? ` ${EN_DASH} ${measurement.label}`
              : "";

            switch (measurement.typeLabel) {
              case ObservationsLabels.BloodPressure:
                displayText =
                  measurement.systolic && measurement.diastolic
                    ? `${measurement.systolic}/${measurement.diastolic}`
                    : measurement.value;
                break;

              case ObservationsLabels.PulseBpmInTreeView:
                displayText = measurement.pulse
                  ? `${measurement.pulse} bpm`
                  : `${measurement.value} bpm`;
                break;

              default:
                displayText = measurement.value;
                break;
            }

            return (
              <li key={measurement.id}>
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: 4 }}
                >
                  <Text>
                    {formatDateStringToTime(measurement.timestamp)} {EN_DASH}{" "}
                  </Text>
                  <Text
                    styles={{
                      root: {
                        fontWeight: FontWeights.semibold
                      }
                    }}
                  >
                    {displayText}
                  </Text>
                  <Text>{labelText}</Text>
                </Stack>
              </li>
            );
          })}
        </NativeList>
      ) : (
        <Stack>
          {measurements.length === 1 ? (
            <Stack
              tokens={{ childrenGap: 4 }}
              horizontal
              verticalAlign="center"
            >
              <Text
                styles={{
                  root: {
                    fontSize: FontSizes.size12,
                    color: theme.palette.neutralSecondary,
                    flex: 1
                  }
                }}
              >
                {measurements[0].typeLabel ===
                ObservationsLabels.TemperatureInTreeView
                  ? ObservationsLabels.Temperature
                  : measurements[0].typeLabel}
              </Text>
              {core.hasAccessToSecGroup(measurements[0].secGroupId) ? (
                <Text
                  styles={{
                    root: {
                      color: theme.palette.neutralPrimary,
                      fontWeight: FontWeights.semibold,
                      flex: 1
                    }
                  }}
                >
                  {measurements[0].value}
                </Text>
              ) : (
                <StackItem styles={{ root: { flex: 1 } }}>
                  <ConfidentialByProviderText
                    secGroupId={measurements[0].secGroupId ?? ""}
                    showIcon
                    styles={{
                      root: {
                        ...noWrap,
                        flex: 1
                      }
                    }}
                  />
                </StackItem>
              )}
            </Stack>
          ) : (
            measurements.map(measurement => {
              return (
                <Stack key={measurement.id} tokens={{ childrenGap: 4 }}>
                  {formatDateStringToTime(measurement.timestamp)}
                  {EN_DASH}
                  {core.hasAccessToSecGroup(measurements[0].secGroupId) ? (
                    measurement.value
                  ) : (
                    <ConfidentialByProviderText
                      secGroupId={measurements[0].secGroupId ?? ""}
                      showIcon
                      styles={{
                        root: {
                          ...noWrap
                        }
                      }}
                    />
                  )}
                </Stack>
              );
            })
          )}
        </Stack>
      )}
    </Stack>
  );
};
