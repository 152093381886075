import { observer } from "mobx-react-lite";
import { FC, useContext, useRef } from "react";

import { DateTime } from "@bps/utils";
import { StatementGenerateModalFields } from "@modules/billing/screens/statement/components/StatementGenerateModalFields.tsx";
import { getSubmissionFormStyles } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/AppointmentForm.styles.tsx";
import { SecondColumnWrapper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/SecondColumnWrapper.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { StatementItemsContext } from "../context/StatementItemsContext.ts";
import { StatementGenerateFormValues } from "./StatementGenerateModal.type.ts";
import { StatementSecondColumn } from "./StatementSecondColumn.tsx";

export const StatementGenerateModal: FC = observer(() => {
  const { setIsStatementNewModalVisible, handleSubmitAction } = useContext(
    StatementItemsContext
  );

  const { billing } = useStores();

  const initialValues: StatementGenerateFormValues = {
    startDate: DateTime.today()
      .minus({ months: 1 })
      .startOf("month")
      .toJSDate(),
    endDate: DateTime.today().minus({ months: 1 }).endOf("month").toJSDate(),
    owingOver: undefined,
    creditOver: undefined,
    valueFilter: false
  };

  const onSubmit = async (values: StatementGenerateFormValues) => {
    await handleSubmitAction(values);
    setIsStatementNewModalVisible(false);
  };

  const persistedInitialValues = useRef(initialValues);

  return (
    <SubmissionFormDialog<StatementGenerateFormValues>
      dialogName="statementRun"
      initialValues={persistedInitialValues.current}
      onSubmitSucceeded={() => setIsStatementNewModalVisible(false)}
      onSubmit={onSubmit}
      hideButtons
      dialogProps={{
        showTitleSeparator: false,
        onDismiss() {
          setIsStatementNewModalVisible(false);
          billing.ui.setStatementSecondColumn(undefined);
        },
        maxWidth: "auto",
        minWidth: "auto",
        dialogContentProps: {
          styles: {
            title: { display: "none" },
            inner: { padding: 0, height: "100%" },
            innerContent: { padding: 0 }
          }
        }
      }}
      styles={getSubmissionFormStyles(billing.ui.statementSecondColumn)}
      sidePanel={
        <SecondColumnWrapper heading="Filter statements">
          <StatementSecondColumn />
        </SecondColumnWrapper>
      }
    >
      {() => <StatementGenerateModalFields />}
    </SubmissionFormDialog>
  );
});
