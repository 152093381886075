import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontIcon,
  LabeledText,
  Link,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import {
  DidNotArriveItem,
  DidNotArriveLabel
} from "@modules/booking/screens/did-not-arrive/DidNotArriveListScreen.types.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export const DidNotArriveRescheduledLabel: React.FC<{
  item: DidNotArriveItem;
}> = observer(({ item }) => {
  const { booking } = useStores();
  const theme = useTheme();

  const { calendarEvent } = item;

  const providerName = item.provider?.fullName;

  const getTooltipContent = (
    calendarEvent: CalendarEvent,
    user?: User | undefined
  ) => {
    if (!calendarEvent.rescheduledAppointmentId) {
      return;
    }

    if (calendarEvent.manuallyRescheduledBy) {
      const baseMessage = `Manually rescheduled by ${user?.fullName}`;
      const comment = calendarEvent.manuallyRescheduledComment;

      return comment ? (
        <Stack tokens={{ childrenGap: 8 }}>
          <Text variant="small">{baseMessage}</Text>
          <LabeledText noSemicolon label="Comment">
            {comment}
          </LabeledText>
        </Stack>
      ) : (
        baseMessage
      );
    }

    return `Rescheduled to ${calendarEvent.startDateTime.toDayDefaultFormat()} with ${providerName} by ${user?.fullName}`;
  };

  if (calendarEvent.rescheduledAppointmentId) {
    return (
      <DataFetcher
        fetch={async ({ core }) => {
          const userId =
            calendarEvent.manuallyRescheduledBy || calendarEvent.bookedBy;
          return userId ? await core.getUser(userId) : undefined;
        }}
      >
        {user => (
          <TooltipHost content={getTooltipContent(calendarEvent, user)}>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <FontIcon
                iconName={
                  calendarEvent.manuallyRescheduledBy
                    ? "EventToDoLogo"
                    : "Completed"
                }
                styles={{
                  root: {
                    color: theme.semanticColors.successIcon
                  }
                }}
              />
              <Link>{DidNotArriveLabel.Rescheduled}</Link>
            </Stack>
          </TooltipHost>
        )}
      </DataFetcher>
    );
  }

  return (
    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
      <FontIcon
        iconName="AddEvent"
        styles={{
          root: {
            color: theme.semanticColors.link
          }
        }}
      />
      <Link
        onClick={() =>
          booking.ui.onRebookOrRescheduleButtonClick(
            item.calendarEvent,
            0,
            true
          )
        }
      >
        <span>{DidNotArriveLabel.Reschedule}</span>
      </Link>
    </Stack>
  );
});
