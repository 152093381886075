import { flatten } from "@bps/fluent-ui";
import { DateTime, last } from "@bps/utils";
import {
  ClinicalDataType,
  GoalDataItemDto,
  GoalsDataItemDto,
  MeasurementDto,
  MeasurementType,
  PSFSActivityItemDto,
  PSFSCloseReason,
  PSFSContextClinicalDataItemDto,
  PSFSQuestionnaireResponseDto,
  PSFSReason,
  QuestionnaireDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { EncounterClinicalData } from "@stores/clinical/models/EncounterClinicalData.ts";
import {
  getClinicalDataLastUpdatedDate,
  getClinicalDataLastUpdatedUserId
} from "@stores/clinical/utils/clinical.utils.ts";

import { SOTAPMultiProviderClinicalDataHelper } from "../../../SOTAP/context/SOTAPMultiProviderClinicalDataHelper .ts";
import { PSFSFormValues } from "../PSFSForm.types.ts";

export class PSFSFormModel {
  constructor(
    private clinical: ClinicalStore,
    private clinicalRecord: ClinicalRecord,
    private options: {
      onMeasurementSaved?: (measurement: MeasurementDto) => void;
      contextId: string;
      hasPTPPermission: boolean;
    }
  ) {
    this.multiProviderHelper = new SOTAPMultiProviderClinicalDataHelper(
      this.clinicalRecord
    );

    this.context = this.getContext;
  }

  private multiProviderHelper: SOTAPMultiProviderClinicalDataHelper;

  private _claimId?: string;

  set claimId(claimId: string) {
    this._claimId = claimId;
  }

  private get stashedPsfsResponseClinicalData():
    | PSFSQuestionnaireResponseDto
    | undefined {
    return this.clinicalRecord.stashedClinicalData?.psfs?.responses.find(
      r => r.contextId === this.context?.contextId
    );
  }

  private get psfsResponseClinicalData():
    | PSFSQuestionnaireResponseDto
    | undefined {
    return this.clinicalRecord.stashedClinicalData?.originalDto?.psfs?.responses.find(
      r => r.contextId === this.context?.contextId
    );
  }

  //Note: the context might be undefined in initial stage of creating PSFSFormModel
  //there will be a ticket to refactor PSFSForm creation workflow soon.
  public context;

  private isBaseLine: boolean = false;

  private get getContext(): PSFSContextClinicalDataItemDto {
    const stashedContext = this.options.contextId
      ? this.clinicalRecord.stashedClinicalData?.psfsContext?.contexts.find(
          c => c.contextId === this.options.contextId
        )
      : undefined;

    if (!stashedContext) {
      this.isBaseLine = true;
      const context: PSFSContextClinicalDataItemDto = {
        contextId: this.options.contextId,
        diagnosis: {
          code: "",
          originalText: ""
        },
        side: "",
        activities: [],
        isClosed: false
      };
      return context;
    }

    return stashedContext;
  }

  public get contextId(): string {
    return this.context.contextId;
  }

  public lastUpdatedDate = getClinicalDataLastUpdatedDate(
    this.stashedPsfsResponseClinicalData
  );

  public lastUpdatedUserId = getClinicalDataLastUpdatedUserId(
    this.stashedPsfsResponseClinicalData
  );

  get initialValues() {
    const savedValues = this.getSavedValues();
    if (this.isBaseLine) {
      savedValues.reason = PSFSReason.Baseline;
    }
    if (
      this.clinicalRecord.getDischargeStatus(
        this.clinicalRecord.openEncounter?.businessRole
      ) &&
      !this.isBaseLine
    ) {
      savedValues.reason = PSFSReason.Discharge;
    }

    if (!savedValues.diagnosisKey && !savedValues.side) {
      const primaryDiagnosis = this.clinicalRecord.episodeOfCare?.diagnoses
        ? this.clinicalRecord.episodeOfCare?.diagnoses.find(
            x => x.isPrimaryDiagnosis
          )
        : this.clinicalRecord.stashedClinicalData?.diagnoses?.diagnoses?.find(
            x => x.isPrimaryDiagnosis
          );

      const primaryMedicalHistory = this.clinicalRecord.medicalHistories.find(
        x =>
          x.isPrimary &&
          x.episodeOfCareId === this.clinicalRecord.episodeOfCare?.id
      );

      return {
        diagnosisKey:
          primaryDiagnosis?.diagnosisCode?.code &&
          primaryDiagnosis?.diagnosisCode?.originalText
            ? `${primaryDiagnosis?.diagnosisCode?.code}${primaryDiagnosis?.diagnosisCode?.originalText}`
            : `${primaryMedicalHistory?.diagnosisCode?.code}${primaryMedicalHistory?.diagnosisCode?.originalText}`,
        side:
          primaryDiagnosis?.diagnosisSide ??
          primaryMedicalHistory?.diagnosisSide,
        ...savedValues
      };
    } else {
      return savedValues;
    }
  }

  private getSavedValues = (): PSFSFormValues => {
    const values: PSFSFormValues = { items: {} };

    if (this.stashedPsfsResponseClinicalData) {
      values.confidential = !!this.stashedPsfsResponseClinicalData.secGroupId;

      values.reason = this.stashedPsfsResponseClinicalData.reason;
      this.stashedPsfsResponseClinicalData.items.forEach(item => {
        values.items[`q${item.questionnaireItemId}a2`] = item.answer;
      });
      if (
        this.context &&
        this.stashedPsfsResponseClinicalData.reason === PSFSReason.Baseline
      ) {
        this.context.activities.map(activity => {
          values.items[`q${activity.questionnaireItemId}a1`] = activity.text;

          return activity;
        });
        values.diagnosisKey = this.clinical.getTerminologyKey(
          this.context.diagnosis?.code,
          this.context.diagnosis?.originalText
        );
        values.side = this.context.side;
      }
    }

    return values;
  };

  private findSavedQuestionGuid = (questionnaireItemId: number) => {
    if (!!this.psfsResponseClinicalData) {
      const item = this.psfsResponseClinicalData.items.find(
        i => i.questionnaireItemId === questionnaireItemId
      );
      return item?.id ?? undefined;
    }
    return undefined;
  };

  onSubmit =
    (questionnaire: QuestionnaireDto) => async (values: PSFSFormValues) => {
      // A PSFS saves/updates two clinical data documents
      // A context which is used for grouping related PSFS
      // And the PSFS document for this encounter

      const data = this.getStashedClinicalData(questionnaire)(values);
      await this.clinicalRecord.saveClinicalData({
        psfsContext: data.psfsContext
      });
      await this.clinicalRecord.saveClinicalData({ psfs: data.psfs });

      const context = data?.psfsContext
        ? data?.psfsContext.contexts.map(c => c.activities)
        : [];

      const activities = flatten([last(context) ?? []]);

      this.setPSFSGoals(activities);
      const request = {
        patientId: this.clinicalRecord.patient?.id,
        encounterId: this.clinicalRecord.openEncounter?.id,
        types: [MeasurementType.PSFS],
        contextId: this.contextId
      };

      const response = await this.clinical.getMeasurements(request);

      const measurements = response.results;

      if (measurements && measurements.length === 1) {
        this.clinicalRecord.updateMeasurements(measurements);

        if (this.options.onMeasurementSaved) {
          this.options.onMeasurementSaved(measurements[0]);
        }
      } else {
        this.clinical.root.notification.error(
          "Unable to retrieve the total clinical tool score."
        );
      }
    };

  private setPSFSGoals = (activities: PSFSActivityItemDto[]) => {
    if (this.options.hasPTPPermission) {
      const treatmentPlan =
        this.multiProviderHelper.getInitialValuePatientTreatmentPlanGoalsData();

      const goals: GoalDataItemDto[] = [];
      if (
        !treatmentPlan?.goals ||
        (treatmentPlan.goals && !treatmentPlan?.psfsGoalsAdded)
      ) {
        if (activities?.length > 0) {
          activities.forEach(question => {
            if (treatmentPlan) {
              goals?.push({
                goal: question.text,
                isAchieved: false,
                startDate: DateTime.today().toISODate()
              });
            }
          });
        }

        const stashedTreatmentPlans =
          this.clinicalRecord.stashedClinicalData?.patientTreatmentPlan;

        if (treatmentPlan) {
          let index: number | undefined;

          if (!this.multiProviderHelper.multiProviderClaimsAllowed) {
            index = stashedTreatmentPlans?.treatmentPlans?.findIndex(
              d => d.linkId === this.clinicalRecord.episodeOfCare?.id
            );
          } else {
            index = stashedTreatmentPlans?.treatmentPlans?.findIndex(
              d =>
                d.businessRoleCode ===
                  this.clinicalRecord.openEncounter?.businessRole &&
                d.linkId === this.clinicalRecord.episodeOfCare?.id
            );
          }

          if (
            index &&
            stashedTreatmentPlans &&
            stashedTreatmentPlans.treatmentPlans
          ) {
            stashedTreatmentPlans.treatmentPlans[index].goals = goals;

            this.clinicalRecord.stashedClinicalData?.updateFromPatch({
              patientTreatmentPlan: {
                ...stashedTreatmentPlans,
                treatmentPlans: stashedTreatmentPlans?.treatmentPlans
              }
            });
          }
        }
      }
    } else {
      const goalsData: GoalsDataItemDto | undefined = {
        ...this.multiProviderHelper.getInitialValueGoalsData()
      };

      const claimGoal = goalsData?.claimGoals
        ? { ...goalsData?.claimGoals[0] }
        : undefined;

      if (
        !goalsData?.claimGoals ||
        (claimGoal && !goalsData?.claimGoals[0].psfsGoalsAdded)
      ) {
        if (activities?.length > 0) {
          activities.forEach(question => {
            if (claimGoal) {
              claimGoal.goals?.push({
                goal: question.text,
                isAchieved: false,
                startDate: DateTime.today().toISODate()
              });
            }
          });
        }

        const currentGoals = this.clinicalRecord.stashedClinicalData?.goals;

        if (claimGoal) {
          this.clinicalRecord.stashedClinicalData?.updateFromPatch({
            goals: {
              ...currentGoals,
              dataItems: [
                ...[
                  ...(currentGoals?.dataItems ?? []),
                  { claimGoals: [claimGoal] }
                ]
              ]
            }
          });
        }
      }
    }
  };

  public getStashedClinicalData =
    (questionnaire: QuestionnaireDto) =>
    (
      values: PSFSFormValues
    ): Pick<EncounterClinicalData, "psfs" | "psfsContext"> => {
      const { psfs } = this.getStashedPsfsClinicalData(questionnaire, values);
      const { psfsContext } = this.getStashedPsfsContextClinicalData(
        questionnaire,
        values
      );
      return { psfsContext, psfs };
    };

  // Create or update a series context,
  // which is used to group multiple PSFS over multiple encounters via context ID
  // Context contains:
  // Diagnosis - the diagnosis the series is for
  // Side - the applicable side of the diagnosis
  // Activities - The activities span across the entire series
  private getStashedPsfsContextClinicalData = (
    questionnaire: QuestionnaireDto,
    values: PSFSFormValues
  ) => {
    const activities: {
      id: string | undefined;
      text: string;
      questionnaireItemId: number;
    }[] = [];

    // create the list of activities from the form
    questionnaire.items.forEach(item => {
      if (values.items[`q${item.id}a2`]) {
        const activity = this.context?.activities.find(
          a => a.questionnaireItemId === item.id
        );

        const newItem = {
          id: activity?.id ?? undefined,
          text:
            values.reason === PSFSReason.Baseline
              ? values.items[`q${item.id}a1`]
              : activity?.text ?? "",
          questionnaireItemId: item.id
        };
        activities.push(newItem);
      }
    });

    const diagnosisKey = values.diagnosisKey ?? "";

    const term = this.clinical.getTerminologyFromMap(diagnosisKey);
    const diagnosisText = term?.text ?? this.context?.diagnosis?.originalText;
    const termCode = term?.code ?? this.context?.diagnosis?.code;
    const termCodeSystem =
      term?.codeSystem ?? this.context?.diagnosis?.codeSystem;

    const termVersion = term?.version ?? this.context?.diagnosis?.version;

    const diagnosisCode =
      values.reason === PSFSReason.Baseline
        ? termCode
        : this.context?.diagnosis?.code;

    const context: PSFSContextClinicalDataItemDto = {
      contextId: this.contextId,
      diagnosis: {
        code: diagnosisCode!,
        originalText: diagnosisText!,
        codeSystem: termCodeSystem,
        version: termVersion
      },
      side:
        values.reason === PSFSReason.Baseline
          ? values.side ?? ""
          : this.context?.side ?? "",
      activities,
      isClosed: values.reason === PSFSReason.Discharge,
      reasonForClose:
        values.reason === PSFSReason.Discharge
          ? PSFSCloseReason.Discharged
          : undefined,
      claimId: this._claimId
    };

    const contextData =
      this.clinicalRecord.stashedClinicalData?.originalDto?.psfsContext;

    if (contextData) {
      const existingContexts = contextData.contexts;
      const updatedContexts: PSFSContextClinicalDataItemDto[] = [];

      existingContexts.forEach(existingContext => {
        // check for existing context and update it
        if (existingContext.contextId === context.contextId) {
          const updatedContext: PSFSContextClinicalDataItemDto = {
            ...existingContext,
            diagnosis: context.diagnosis,
            side: context.side,
            activities: context.activities,
            isClosed: context.isClosed,
            reasonForClose:
              values.reason !== PSFSReason.Discharge &&
              existingContext.reasonForClose
                ? ""
                : context.reasonForClose
          };

          updatedContexts.push(updatedContext);
        } else {
          // otherwise just add it to the list
          updatedContexts.push(existingContext);
        }
      });

      // check the updated list for the context id
      // if it's not there, it means it wasn't an existing context, so add it to the list
      if (updatedContexts.findIndex(c => c.contextId === this.contextId) < 0) {
        updatedContexts.push(context);
      }

      return {
        psfsContext: {
          ...contextData,
          contexts: updatedContexts
        }
      };
    }
    return {
      psfsContext: {
        contexts: [context]
      }
    };
  };

  private getStashedPsfsClinicalData = (
    questionnaire: QuestionnaireDto,
    values: PSFSFormValues
  ) => {
    const secGroupId = !!values.confidential
      ? this.clinicalRecord.core.user?.privateSecGroupId
      : undefined;

    let items: {
      id: string | undefined;
      answer: string;
      questionnaireItemId: number;
    }[] = [];

    questionnaire.items.forEach(item => {
      const answer = values.items[`q${item.id}a2`];
      if (answer) {
        const newItem = {
          id: this.findSavedQuestionGuid(item.id),
          answer,
          questionnaireItemId: item.id
        };
        items = [...items, newItem];
      }
    });

    const response: PSFSQuestionnaireResponseDto = {
      questionnaireId: questionnaire.id,
      questionnaireCode: questionnaire.code,
      contextId: this.contextId,
      reason: values.reason ?? "",
      items,
      secGroupId
    };

    const psfsData = this.clinicalRecord.stashedClinicalData?.originalDto?.psfs;
    if (psfsData) {
      const existingResponses = psfsData.responses;
      const updatedResponses: PSFSQuestionnaireResponseDto[] = [];

      existingResponses.forEach(existingResponse => {
        // check for existing context and update it
        if (existingResponse.contextId === this.contextId) {
          const updatedResponse: PSFSQuestionnaireResponseDto = {
            ...existingResponse,
            reason: response.reason,
            items: response.items,
            secGroupId: response.secGroupId
          };

          updatedResponses.push(updatedResponse);
        } else {
          // otherwise just add it to the list
          updatedResponses.push(existingResponse);
        }
      });

      // check the updated list for the context id
      // if it's not there, it means it wasn't an existing context, so add it to the list
      if (updatedResponses.findIndex(c => c.contextId === this.contextId) < 0) {
        updatedResponses.push(response);
      }

      return {
        psfs: {
          ...psfsData,
          responses: updatedResponses
        }
      };
    } else {
      return {
        psfs: {
          responses: [response]
        }
      };
    }
  };

  public onCancel = (): void => {
    this.clinical.ui.closePatientRecordContentForm(
      this.clinicalRecord.id,
      ClinicalDataType.PSFS,
      this.options.contextId
    );
    this.clinicalRecord.stashedClinicalData?.resetStashedClinicalData([
      "psfs",
      "psfsContext"
    ]);
  };
}
